export const UserRole = {
  Admin: 0,
  Editor: 1,
};

/*
Menu Types:
"menu-default", "menu-sub-hidden", "menu-hidden"
*/

export const baseUrl = process.env.REACT_APP_SERVER_URI;
export const userId =
  JSON.parse(localStorage.getItem('ustelephony24User'))?.uid || '';
export const countries = [
  {
    value: 'US',
    label: 'United States of America',
    key: 'US',
  },
  {
    value: 'CA',
    label: 'Canada',
    key: 'CA',
  },
];

export const numberTypes = [
  {
    value: 'local',
    label: 'Local',
    key: 'local',
  },
  {
    value: 'tollfree',
    label: 'Toll Free',
    key: 'tollfree',
  },
];

export const pricingTypes = [
  {
    value: 'callUsa',
    label: 'Call | USA',
    key: 'callUsa',
  },
  {
    value: 'smsUsa',
    label: 'SMS | USA',
    key: 'smsUsa',
  },
  {
    value: 'callCanada',
    label: 'Call | Canada',
    key: 'callCanada',
  },
  {
    value: 'smsCanada',
    label: 'SMS | Canada',
    key: 'smsCanada',
  },
];

export const registrationTypes = [
  {
    value: 'standard',
    label: 'Standard $4/One time fee',
    key: 'standard',
  },
];

export const defaultMenuType = 'menu-default';

export const subHiddenBreakpoint = 1440;
export const menuHiddenBreakpoint = 768;
export const defaultLocale = 'en';
export const localeOptions = [
  { id: 'en', name: 'English - LTR', direction: 'ltr' },
  { id: 'es', name: 'Español', direction: 'ltr' },
  { id: 'enrtl', name: 'English - RTL', direction: 'rtl' },
];

export const firebaseConfig = {
  apiKey: 'AIzaSyBBksq-Asxq2M4Ot-75X19IyrEYJqNBPcg',
  authDomain: 'gogo-react-login.firebaseapp.com',
  databaseURL: 'https://gogo-react-login.firebaseio.com',
  projectId: 'gogo-react-login',
  storageBucket: 'gogo-react-login.appspot.com',
  messagingSenderId: '216495999563',
};

export const adminRoot = '/app';
export const buyUrl = 'https://1.envato.market/k4z0';
export const searchPath = `${adminRoot}/#`;
export const servicePath = 'https://api.coloredstrategies.com';

export const currentUser = {
  img: '/assets/img/profiles/l-1.jpg',
  date: 'Last seen today 15:24',
};

export const defaultColor = 'light.blueyale';
export const themeColorStorageKey = '__theme_selected_color';
export const isMultiColorActive = false;
export const isDarkSwitchActive = true;
export const defaultDirection = 'ltr';
export const themeRadiusStorageKey = '__theme_radius';
export const isAuthGuardActive = true;
export const colors = [
  'bluenavy',
  'blueyale',
  'blueolympic',
  'greenmoss',
  'greenlime',
  'purplemonster',
  'orangecarrot',
  'redruby',
  'yellowgranola',
  'greysteel',
];

export const companyTypes = [
  'PRIVATE',
  'PUBLIC',
  'NON_PROFIT',
  'GOVERNMENT',
  'INDIVIDUAL',
];

export const industries = [
  'PROFESSIONAL',
  'REAL_ESTATE',
  'HEALTHCARE',
  'HUMAN_RESOURCES',
  'ENERGY',
  'ENTERTAINMENT',
  'RETAIL',
  'TRANSPORTATION',
  'AGRICULTURE',
  'INSURANCE',
  'POSTAL',
  'EDUCATION',
  'HOSPITALITY',
  'FINANCIAL',
  'POLITICAL',
  'GAMBLING',
  'LEGAL',
  'CONSTRUCTION',
  'NGO',
  'MANUFACTURING',
  'GOVERNMENT',
  'TECHNOLOGY',
  'COMMUNICATION',
];

export const usStateCodes = {
  AL: 'Alabama',
  AK: 'Alaska',
  AS: 'American Samoa',
  AZ: 'Arizona',
  AR: 'Arkansas',
  CA: 'California',
  CO: 'Colorado',
  CT: 'Connecticut',
  DE: 'Delaware',
  DC: 'District Of Columbia',
  FM: 'Federated States Of Micronesia',
  FL: 'Florida',
  GA: 'Georgia',
  GU: 'Guam',
  HI: 'Hawaii',
  ID: 'Idaho',
  IL: 'Illinois',
  IN: 'Indiana',
  IA: 'Iowa',
  KS: 'Kansas',
  KY: 'Kentucky',
  LA: 'Louisiana',
  ME: 'Maine',
  MH: 'Marshall Islands',
  MD: 'Maryland',
  MA: 'Massachusetts',
  MI: 'Michigan',
  MN: 'Minnesota',
  MS: 'Mississippi',
  MO: 'Missouri',
  MT: 'Montana',
  NE: 'Nebraska',
  NV: 'Nevada',
  NH: 'New Hampshire',
  NJ: 'New Jersey',
  NM: 'New Mexico',
  NY: 'New York',
  NC: 'North Carolina',
  ND: 'North Dakota',
  MP: 'Northern Mariana Islands',
  OH: 'Ohio',
  OK: 'Oklahoma',
  OR: 'Oregon',
  PW: 'Palau',
  PA: 'Pennsylvania',
  PR: 'Puerto Rico',
  RI: 'Rhode Island',
  SC: 'South Carolina',
  SD: 'South Dakota',
  TN: 'Tennessee',
  TX: 'Texas',
  UT: 'Utah',
  VT: 'Vermont',
  VI: 'Virgin Islands',
  VA: 'Virginia',
  WA: 'Washington',
  WV: 'West Virginia',
  WI: 'Wisconsin',
  WY: 'Wyoming',
};

export const positions = [
  'DIRECTOR',
  'GM',
  'VP',
  'CEO',
  'CFO',
  'GENERAL_COUNSEL',
  'OTHER',
];

export const mixedContentType = ['MIXED', 'LOW_VOLUME'];
