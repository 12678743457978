import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import axios from 'axios';

import {
  SMS_QUEUES_GET_LIST,
  SMS_QUEUES_ADD_ITEM,
  SMS_QUEUES_EDIT_ITEM,
  SMS_QUEUES_GET_EMPLOYEE_LIST,
} from '../actions';

import {
  getSmsQueuesListSuccess,
  getSmsQueuesListError,
  addSmsQueueItemSuccess,
  addSmsQueueItemError,
  editSmsQueueItemSuccess,
  editSmsQueueItemError,
  getSmsQueuesEmployeeListsSuccess,
  getSmsQueuesEmployeeListsError,
} from './actions';

const getSmsQueuesEmployeeListRequest = async () => {
  // eslint-disable-next-line no-return-await
  return await new Promise((success) => {
    axios
      .get('/dashboard/getAllEmployees')
      .then((response) => success(response?.data?.employees))
      .catch(() => success([]));
  })
    .then((response) => response)
    .catch((error) => error);
};

function* getSmsQueuesEmployeeListItems() {
  try {
    const response = yield call(getSmsQueuesEmployeeListRequest);
    yield put(getSmsQueuesEmployeeListsSuccess(response));
  } catch (error) {
    yield put(getSmsQueuesEmployeeListsError(error));
  }
}

const getSmsQueuesListRequest = async () => {
  // eslint-disable-next-line no-return-await
  return await new Promise((success) => {
    axios
      .get('/flow/smsQueues/')
      .then((response) => success(Object.values(response?.data?.payload)))
      .catch(() => success([]));
  })
    .then((response) => response)
    .catch((error) => error);
};

function* getSmsQueuesListItems() {
  try {
    const response = yield call(getSmsQueuesListRequest);
    yield put(getSmsQueuesListSuccess(response));
  } catch (error) {
    yield put(getSmsQueuesListError(error));
  }
}

const addSmsQueueItemRequest = async (item) => {
  // eslint-disable-next-line no-return-await
  return await new Promise((success) => {
    axios
      .post('/flow/smsQueue', { ...item })
      .then((response) => success(response.data.queues))
      .catch(() => success(null));
  })
    .then((response) => response)
    .catch((error) => error);
};

function* addSmsQueueItem({ payload }) {
  try {
    const response = yield call(addSmsQueueItemRequest, payload);
    yield put(addSmsQueueItemSuccess(response));
  } catch (error) {
    yield put(addSmsQueueItemError(error));
  }
}

const editSmsQueueItemRequest = async (item) => {
  // eslint-disable-next-line no-return-await
  return await new Promise((success) => {
    axios
      .put(`/flow/smsQueue/${item.smsQueueId}`, { ...item })
      .then((response) => success(response.data.smsQueues))
      .catch(() => success(null));
  })
    .then((response) => response)
    .catch((error) => error);
};

function* editSmsQueueItem({ payload }) {
  try {
    const response = yield call(editSmsQueueItemRequest, payload);
    yield put(editSmsQueueItemSuccess(response));
  } catch (error) {
    yield put(editSmsQueueItemError(error));
  }
}

export function* watchGetList() {
  yield takeEvery(SMS_QUEUES_GET_LIST, getSmsQueuesListItems);
}

export function* watchGetQueuesEmployeeList() {
  yield takeEvery(SMS_QUEUES_GET_EMPLOYEE_LIST, getSmsQueuesEmployeeListItems);
}

export function* wathcAddItem() {
  yield takeEvery(SMS_QUEUES_ADD_ITEM, addSmsQueueItem);
}

export function* wathcEditItem() {
  yield takeEvery(SMS_QUEUES_EDIT_ITEM, editSmsQueueItem);
}

export default function* rootSaga() {
  yield all([
    fork(watchGetList),
    fork(wathcAddItem),
    fork(wathcEditItem),
    fork(watchGetQueuesEmployeeList),
  ]);
}
