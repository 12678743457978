import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import axios from 'axios';

import {
  BULK_SMS_GET_LIST,
  BULK_SMS_ADD_ITEM,
  BULK_SMS_GET_NUMBERS_LIST,
} from '../actions';

import {
  getBulkSmsListSuccess,
  getBulkSmsListError,
  addBulkSmsItemSuccess,
  addBulkSmsItemError,
  getBulkSmsNumbersListsSuccess,
  getBulkSmsNumbersListsError,
} from './actions';

const getBulkSmsNumbersListRequest = async () => {
  // eslint-disable-next-line no-return-await
  return await new Promise((success) => {
    axios
      .get('/user/getNumbersForBulkSMS')
      .then((response) => success(response?.data?.payload))
      .catch(() => success([]));
  })
    .then((response) => response)
    .catch((error) => error);
};

function* getBulkSmsNumberssItems() {
  try {
    const response = yield call(getBulkSmsNumbersListRequest);
    yield put(getBulkSmsNumbersListsSuccess(response));
  } catch (error) {
    yield put(getBulkSmsNumbersListsError(error));
  }
}

const getBulkSmsListRequest = async () => {
  // eslint-disable-next-line no-return-await
  return await new Promise((success) => {
    axios
      .get('/bulkSms/bulkSms/')
      .then(
        (response) => response && success(Object.values(response?.data.payload))
      )
      .catch(() => success([]));
  })
    .then((response) => response)
    .catch((error) => error);
};

function* getBulkSmsListItems() {
  try {
    const response = yield call(getBulkSmsListRequest);
    yield put(getBulkSmsListSuccess(response));
  } catch (error) {
    yield put(getBulkSmsListError(error));
  }
}

const addBulkSmsItemRequest = async (item) => {
  // eslint-disable-next-line no-return-await
  return await new Promise((success) => {
    axios
      .post('/bulkSms', { ...item })
      .then((response) => success(response?.data?.payload))
      .catch(() => success(null));
  })
    .then((response) => response)
    .catch((error) => error);
};

function* addBulkSmsItem({ payload }) {
  try {
    const response = yield call(addBulkSmsItemRequest, payload);
    yield put(addBulkSmsItemSuccess(response));
  } catch (error) {
    yield put(addBulkSmsItemError(error));
  }
}

export function* watchGetList() {
  yield takeEvery(BULK_SMS_GET_LIST, getBulkSmsListItems);
}

export function* watchGetBulkSmsNumberssList() {
  yield takeEvery(BULK_SMS_GET_NUMBERS_LIST, getBulkSmsNumberssItems);
}

export function* wathcAddItem() {
  yield takeEvery(BULK_SMS_ADD_ITEM, addBulkSmsItem);
}

export default function* rootSaga() {
  yield all([
    fork(watchGetList),
    fork(wathcAddItem),
    fork(watchGetBulkSmsNumberssList),
  ]);
}
