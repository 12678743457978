// eslint-disable-next-line import/no-cycle
import {
  QUEUES_GET_LIST,
  QUEUES_GET_LIST_SUCCESS,
  QUEUES_GET_LIST_ERROR,
  QUEUES_ADD_ITEM,
  QUEUES_ADD_ITEM_SUCCESS,
  QUEUES_ADD_ITEM_ERROR,
  QUEUES_EDIT_ITEM,
  QUEUES_EDIT_ITEM_SUCCESS,
  QUEUES_EDIT_ITEM_ERROR,
  QUEUES_GET_EMPLOYEE_LIST,
  QUEUES_GET_EMPLOYEE_LIST_SUCCESS,
  QUEUES_GET_EMPLOYEE_LIST_ERROR,
} from '../actions';

export const getQueuesList = () => ({
  type: QUEUES_GET_LIST,
});

export const getQueuesListSuccess = (items) => ({
  type: QUEUES_GET_LIST_SUCCESS,
  payload: items,
});

export const getQueuesListError = (error) => ({
  type: QUEUES_GET_LIST_ERROR,
  payload: error,
});

export const getQueuesEmployeeLists = () => ({
  type: QUEUES_GET_EMPLOYEE_LIST,
});

export const getQueuesEmployeeListsSuccess = (items) => ({
  type: QUEUES_GET_EMPLOYEE_LIST_SUCCESS,
  payload: items,
});

export const getQueuesEmployeeListsError = (error) => ({
  type: QUEUES_GET_EMPLOYEE_LIST_ERROR,
  payload: error,
});

export const addQueueItem = (item) => ({
  type: QUEUES_ADD_ITEM,
  payload: item,
});

export const addQueueItemSuccess = (items) => ({
  type: QUEUES_ADD_ITEM_SUCCESS,
  payload: items,
});

export const addQueueItemError = (error) => ({
  type: QUEUES_ADD_ITEM_ERROR,
  payload: error,
});

export const editQueueItem = (item) => ({
  type: QUEUES_EDIT_ITEM,
  payload: item,
});

export const editQueueItemSuccess = (items) => ({
  type: QUEUES_EDIT_ITEM_SUCCESS,
  payload: items,
});

export const editQueueItemError = (error) => ({
  type: QUEUES_EDIT_ITEM_ERROR,
  payload: error,
});
