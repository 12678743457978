// eslint-disable-next-line import/no-cycle
import {
  CALLBACK_GET_LIST,
  CALLBACK_GET_LIST_SUCCESS,
  CALLBACK_GET_LIST_ERROR,
  CALLBACK_ADD_ITEM,
  CALLBACK_ADD_ITEM_SUCCESS,
  CALLBACK_ADD_ITEM_ERROR,
  CALLBACK_EDIT_ITEM,
  CALLBACK_EDIT_ITEM_SUCCESS,
  CALLBACK_EDIT_ITEM_ERROR,
  CALLBACK_GET_EMPLOYEE_LIST,
  CALLBACK_GET_EMPLOYEE_LIST_SUCCESS,
  CALLBACK_GET_EMPLOYEE_LIST_ERROR,
  CALLBACK_GET_NUMBER_LIST,
  CALLBACK_GET_NUMBER_LIST_SUCCESS,
  CALLBACK_GET_NUMBER_LIST_ERROR,
} from '../actions';

export const getCallbacksList = () => ({
  type: CALLBACK_GET_LIST,
});

export const getCallbacksListSuccess = (items) => ({
  type: CALLBACK_GET_LIST_SUCCESS,
  payload: items,
});

export const getCallbacksListError = (error) => ({
  type: CALLBACK_GET_LIST_ERROR,
  payload: error,
});

export const getCallbacksEmployeeLists = () => ({
  type: CALLBACK_GET_EMPLOYEE_LIST,
});

export const getCallbacksEmployeeListsSuccess = (items) => ({
  type: CALLBACK_GET_EMPLOYEE_LIST_SUCCESS,
  payload: items,
});

export const getCallbacksEmployeeListsError = (error) => ({
  type: CALLBACK_GET_EMPLOYEE_LIST_ERROR,
  payload: error,
});

export const getCallbacksNumberLists = () => ({
  type: CALLBACK_GET_NUMBER_LIST,
});

export const getCallbacksNumberListsSuccess = (items) => ({
  type: CALLBACK_GET_NUMBER_LIST_SUCCESS,
  payload: items,
});

export const getCallbacksNumberListsError = (error) => ({
  type: CALLBACK_GET_NUMBER_LIST_ERROR,
  payload: error,
});

export const addCallbackItem = (item) => ({
  type: CALLBACK_ADD_ITEM,
  payload: item,
});

export const addCallbackItemSuccess = (items) => ({
  type: CALLBACK_ADD_ITEM_SUCCESS,
  payload: items,
});

export const addCallbackItemError = (error) => ({
  type: CALLBACK_ADD_ITEM_ERROR,
  payload: error,
});

export const editCallbackItem = (item) => ({
  type: CALLBACK_EDIT_ITEM,
  payload: item,
});

export const editCallbackItemSuccess = (items) => ({
  type: CALLBACK_EDIT_ITEM_SUCCESS,
  payload: items,
});

export const editCallbackItemError = (error) => ({
  type: CALLBACK_EDIT_ITEM_ERROR,
  payload: error,
});
