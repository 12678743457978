/* eslint-disable import/no-cycle */
/* SETTINGS */
export const CHANGE_LOCALE = 'CHANGE_LOCALE';

/* AUTH */
export const LOGIN_USER = 'LOGIN_USER';
export const LOGIN_USER_SUCCESS = 'LOGIN_USER_SUCCESS';
export const LOGIN_USER_ERROR = 'LOGIN_USER_ERROR';
export const REGISTER_USER = 'REGISTER_USER';
export const REGISTER_USER_SUCCESS = 'REGISTER_USER_SUCCESS';
export const REGISTER_USER_ERROR = 'REGISTER_USER_ERROR';
export const LOGOUT_USER = 'LOGOUT_USER';
export const FORGOT_PASSWORD = 'FORGOT_PASSWORD';
export const FORGOT_PASSWORD_SUCCESS = 'FORGOT_PASSWORD_SUCCESS';
export const FORGOT_PASSWORD_ERROR = 'FORGOT_PASSWORD_ERROR';
export const RESET_PASSWORD = 'RESET_PASSWORD';
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_ERROR = 'RESET_PASSWORD_ERROR';
export const EMPLOYEE_RESET_PASSWORD = 'EMPLOYEE_RESET_PASSWORD';
export const EMPLOYEE_RESET_PASSWORD_SUCCESS =
  'EMPLOYEE_RESET_PASSWORD_SUCCESS';
export const EMPLOYEE_RESET_PASSWORD_ERROR = 'EMPLOYEE_RESET_PASSWORD_ERROR';

/* MENU */
export const MENU_SET_CLASSNAMES = 'MENU_SET_CLASSNAMES';
export const MENU_CONTAINER_ADD_CLASSNAME = 'MENU_CONTAINER_ADD_CLASSNAME';
export const MENU_CLICK_MOBILE_MENU = 'MENU_CLICK_MOBILE_MENU';
export const MENU_CHANGE_DEFAULT_CLASSES = 'MENU_CHANGE_DEFAULT_CLASSES';
export const MENU_CHANGE_HAS_SUB_ITEM_STATUS =
  'MENU_CHANGE_HAS_SUB_ITEM_STATUS';

/* TODOAPP */
export const TODO_GET_LIST = 'TODO_GET_LIST';
export const TODO_GET_LIST_SUCCESS = 'TODO_GET_LIST_SUCCESS';
export const TODO_GET_LIST_ERROR = 'TODO_GET_LIST_ERROR';
export const TODO_GET_LIST_WITH_FILTER = 'TODO_GET_LIST_WITH_FILTER';
export const TODO_GET_LIST_WITH_ORDER = 'TODO_GET_LIST_WITH_ORDER';
export const TODO_GET_LIST_SEARCH = 'TODO_GET_LIST_SEARCH';
export const TODO_ADD_ITEM = 'TODO_ADD_ITEM';
export const TODO_ADD_ITEM_SUCCESS = 'TODO_ADD_ITEM_SUCCESS';
export const TODO_ADD_ITEM_ERROR = 'TODO_ADD_ITEM_ERROR';
export const TODO_SELECTED_ITEMS_CHANGE = 'TODO_SELECTED_ITEMS_CHANGE';

/* Campaigns */
export const CAMPAIGN_GET_LIST = 'CAMPAIGN_GET_LIST';
export const CAMPAIGN_GET_LIST_SUCCESS = 'CAMPAIGN_GET_LIST_SUCCESS';
export const CAMPAIGN_GET_LIST_ERROR = 'CAMPAIGN_GET_LIST_ERROR';
export const CAMPAIGN_ADD_ITEM = 'CAMPAIGN_ADD_ITEM';
export const CAMPAIGN_ADD_ITEM_SUCCESS = 'CAMPAIGN_ADD_ITEM_SUCCESS';
export const CAMPAIGN_ADD_ITEM_ERROR = 'CAMPAIGN_ADD_ITEM_ERROR';

/* QUEUES */
export const QUEUES_GET_LIST = 'QUEUES_GET_LIST';
export const QUEUES_GET_LIST_SUCCESS = 'QUEUES_GET_LIST_SUCCESS';
export const QUEUES_GET_LIST_ERROR = 'QUEUES_GET_LIST_ERROR';
export const QUEUES_ADD_ITEM = 'QUEUES_ADD_ITEM';
export const QUEUES_ADD_ITEM_SUCCESS = 'QUEUES_ADD_ITEM_SUCCESS';
export const QUEUES_ADD_ITEM_ERROR = 'QUEUES_ADD_ITEM_ERROR';
export const QUEUES_EDIT_ITEM = 'QUEUES_EDIT_ITEM';
export const QUEUES_EDIT_ITEM_SUCCESS = 'QUEUES_EDIT_ITEM_SUCCESS';
export const QUEUES_EDIT_ITEM_ERROR = 'QUEUES_EDIT_ITEM_ERROR';
export const QUEUES_GET_EMPLOYEE_LIST = 'QUEUES_GET_EMPLOYEE_LIST';
export const QUEUES_GET_EMPLOYEE_LIST_SUCCESS =
  'QUEUES_GET_EMPLOYEE_LIST_SUCCESS';
export const QUEUES_GET_EMPLOYEE_LIST_ERROR = 'QUEUES_GET_EMPLOYEE_LIST_ERROR';

/* SMS QUEUES */
export const SMS_QUEUES_GET_LIST = 'SMS_QUEUES_GET_LIST';
export const SMS_QUEUES_GET_LIST_SUCCESS = 'SMS_QUEUES_GET_LIST_SUCCESS';
export const SMS_QUEUES_GET_LIST_ERROR = 'SMS_QUEUES_GET_LIST_ERROR';
export const SMS_QUEUES_ADD_ITEM = 'SMS_QUEUES_ADD_ITEM';
export const SMS_QUEUES_ADD_ITEM_SUCCESS = 'SMS_QUEUES_ADD_ITEM_SUCCESS';
export const SMS_QUEUES_ADD_ITEM_ERROR = 'SMS_QUEUES_ADD_ITEM_ERROR';
export const SMS_QUEUES_EDIT_ITEM = 'SMS_QUEUES_EDIT_ITEM';
export const SMS_QUEUES_EDIT_ITEM_SUCCESS = 'SMS_QUEUES_EDIT_ITEM_SUCCESS';
export const SMS_QUEUES_EDIT_ITEM_ERROR = 'SMS_QUEUES_EDIT_ITEM_ERROR';
export const SMS_QUEUES_GET_EMPLOYEE_LIST = 'SMS_QUEUES_GET_EMPLOYEE_LIST';
export const SMS_QUEUES_GET_EMPLOYEE_LIST_SUCCESS =
  'SMS_QUEUES_GET_EMPLOYEE_LIST_SUCCESS';
export const SMS_QUEUES_GET_EMPLOYEE_LIST_ERROR =
  'SMS_QUEUES_GET_EMPLOYEE_LIST_ERROR';

/* Miss Call Box */
export const MISS_CALL_BOX_GET_LIST = 'MISS_CALL_BOX_GET_LIST';
export const MISS_CALL_BOX_GET_LIST_SUCCESS = 'MISS_CALL_BOX_GET_LIST_SUCCESS';
export const MISS_CALL_BOX_GET_LIST_ERROR = 'MISS_CALL_BOX_GET_LIST_ERROR';
export const MISS_CALL_BOX_ADD_ITEM = 'MISS_CALL_BOX_ADD_ITEM';
export const MISS_CALL_BOX_ADD_ITEM_SUCCESS = 'MISS_CALL_BOX_ADD_ITEM_SUCCESS';
export const MISS_CALL_BOX_ADD_ITEM_ERROR = 'MISS_CALL_BOX_ADD_ITEM_ERROR';
export const MISS_CALL_BOX_EDIT_ITEM = 'MISS_CALL_BOX_EDIT_ITEM';
export const MISS_CALL_BOX_EDIT_ITEM_SUCCESS =
  'MISS_CALL_BOX_EDIT_ITEM_SUCCESS';
export const MISS_CALL_BOX_EDIT_ITEM_ERROR = 'MISS_CALL_BOX_EDIT_ITEM_ERROR';
export const MISS_CALL_BOX_GET_EMPLOYEE_LIST =
  'MISS_CALL_BOX_GET_EMPLOYEE_LIST';
export const MISS_CALL_BOX_GET_EMPLOYEE_LIST_SUCCESS =
  'MISS_CALL_BOX_GET_EMPLOYEE_LIST_SUCCESS';
export const MISS_CALL_BOX_GET_EMPLOYEE_LIST_ERROR =
  'MISS_CALL_BOX_GET_EMPLOYEE_LIST_ERROR';
export const MISS_CALL_BOX_GET_NUMBER_LIST = 'MISS_CALL_BOX_GET_NUMBER_LIST';
export const MISS_CALL_BOX_GET_NUMBER_LIST_SUCCESS =
  'MISS_CALL_BOX_GET_NUMBER_LIST_SUCCESS';
export const MISS_CALL_BOX_GET_NUMBER_LIST_ERROR =
  'MISS_CALL_BOX_GET_NUMBER_LIST_ERROR';

/* Callbacks */
export const CALLBACK_GET_LIST = 'CALLBACK_GET_LIST';
export const CALLBACK_GET_LIST_SUCCESS = 'CALLBACK_GET_LIST_SUCCESS';
export const CALLBACK_GET_LIST_ERROR = 'CALLBACK_GET_LIST_ERROR';
export const CALLBACK_ADD_ITEM = 'CALLBACK_ADD_ITEM';
export const CALLBACK_ADD_ITEM_SUCCESS = 'CALLBACK_ADD_ITEM_SUCCESS';
export const CALLBACK_ADD_ITEM_ERROR = 'CALLBACK_ADD_ITEM_ERROR';
export const CALLBACK_EDIT_ITEM = 'CALLBACK_EDIT_ITEM';
export const CALLBACK_EDIT_ITEM_SUCCESS = 'CALLBACK_EDIT_ITEM_SUCCESS';
export const CALLBACK_EDIT_ITEM_ERROR = 'CALLBACK_EDIT_ITEM_ERROR';
export const CALLBACK_GET_EMPLOYEE_LIST = 'CALLBACK_GET_EMPLOYEE_LIST';
export const CALLBACK_GET_EMPLOYEE_LIST_SUCCESS =
  'CALLBACK_GET_EMPLOYEE_LIST_SUCCESS';
export const CALLBACK_GET_EMPLOYEE_LIST_ERROR =
  'CALLBACK_GET_EMPLOYEE_LIST_ERROR';
export const CALLBACK_GET_NUMBER_LIST = 'CALLBACK_GET_NUMBER_LIST';
export const CALLBACK_GET_NUMBER_LIST_SUCCESS =
  'CALLBACK_GET_NUMBER_LIST_SUCCESS';
export const CALLBACK_GET_NUMBER_LIST_ERROR = 'CALLBACK_GET_NUMBER_LIST_ERROR';

/* TIME_CONDITIONS */
export const TIME_CONDITIONS_GET_LIST = 'TIME_CONDITIONS_GET_LIST';
export const TIME_CONDITIONS_GET_LIST_SUCCESS =
  'TIME_CONDITIONS_GET_LIST_SUCCESS';
export const TIME_CONDITIONS_GET_LIST_ERROR = 'TIME_CONDITIONS_GET_LIST_ERROR';
export const TIME_CONDITIONS_ADD_ITEM = 'TIME_CONDITIONS_ADD_ITEM';
export const TIME_CONDITIONS_ADD_ITEM_SUCCESS =
  'TIME_CONDITIONS_ADD_ITEM_SUCCESS';
export const TIME_CONDITIONS_ADD_ITEM_ERROR = 'TIME_CONDITIONS_ADD_ITEM_ERROR';
export const TIME_CONDITIONS_EDIT_ITEM = 'TIME_CONDITIONS_EDIT_ITEM';
export const TIME_CONDITIONS_EDIT_ITEM_SUCCESS =
  'TIME_CONDITIONS_EDIT_ITEM_SUCCESS';
export const TIME_CONDITIONS_EDIT_ITEM_ERROR =
  'TIME_CONDITIONS_EDIT_ITEM_ERROR';
export const TIMECONDITION_DESTINATIONS_LIST =
  'TIMECONDITION_DESTINATIONS_LIST';
export const TIMECONDITION_DESTINATIONS_LIST_SUCCESS =
  'TIMECONDITION_DESTINATIONS_LIST_SUCCESS';
export const TIMECONDITION_DESTINATIONS_LIST_ERROR =
  'TIMECONDITION_DESTINATIONS_LIST_ERROR';
export const TIMECONDITION_NON_DESTINATIONS_LIST =
  'TIMECONDITION_NON_DESTINATIONS_LIST';
export const TIMECONDITION_NON_DESTINATIONS_LIST_SUCCESS =
  'TIMECONDITION_NON_DESTINATIONS_LIST_SUCCESS';
export const TIMECONDITION_NON_DESTINATIONS_LIST_ERROR =
  'TIMECONDITION_NON_DESTINATIONS_LIST_ERROR';

/* IVRS */
export const IVR_GET_LIST = 'IVR_GET_LIST';
export const IVR_GET_LIST_SUCCESS = 'IVR_GET_LIST_SUCCESS';
export const IVR_GET_LIST_ERROR = 'IVR_GET_LIST_ERROR';
export const IVR_ADD_ITEM = 'IVR_ADD_ITEM';
export const IVR_ADD_ITEM_SUCCESS = 'IVR_ADD_ITEM_SUCCESS';
export const IVR_ADD_ITEM_ERROR = 'IVR_ADD_ITEM_ERROR';
export const IVR_EDIT_ITEM = 'IVR_EDIT_ITEM';
export const IVR_EDIT_ITEM_SUCCESS = 'IVR_EDIT_ITEM_SUCCESS';
export const IVR_EDIT_ITEM_ERROR = 'IVR_EDIT_ITEM_ERROR';
export const IVR_FAIL_OVER_DESTINATIONS_LIST =
  'IVR_FAIL_OVER_DESTINATIONS_LIST';
export const IVR_FAIL_OVER_DESTINATIONS_LIST_SUCCESS =
  'IVR_FAIL_OVER_DESTINATIONS_LIST_SUCCESS';
export const IVR_FAIL_OVER_DESTINATIONS_LIST_ERROR =
  'IVR_FAIL_OVER_DESTINATIONS_LIST_ERROR';

/* Voicemail */
export const VOICEMAIL_GET_LIST = 'VOICEMAIL_GET_LIST';
export const VOICEMAIL_GET_LIST_SUCCESS = 'VOICEMAIL_GET_LIST_SUCCESS';
export const VOICEMAIL_GET_LIST_ERROR = 'VOICEMAIL_GET_LIST_ERROR';
export const VOICEMAIL_ADD_ITEM = 'VOICEMAIL_ADD_ITEM';
export const VOICEMAIL_ADD_ITEM_SUCCESS = 'VOICEMAIL_ADD_ITEM_SUCCESS';
export const VOICEMAIL_ADD_ITEM_ERROR = 'VOICEMAIL_ADD_ITEM_ERROR';
export const VOICEMAIL_EDIT_ITEM = 'VOICEMAIL_EDIT_ITEM';
export const VOICEMAIL_EDIT_ITEM_SUCCESS = 'VOICEMAIL_EDIT_ITEM_SUCCESS';
export const VOICEMAIL_EDIT_ITEM_ERROR = 'VOICEMAIL_EDIT_ITEM_ERROR';
export const VOICEMAIL_GET_EMPLOYEE_LIST = 'VOICEMAIL_GET_EMPLOYEE_LIST';
export const VOICEMAIL_GET_EMPLOYEE_LIST_SUCCESS =
  'VOICEMAIL_GET_EMPLOYEE_LIST_SUCCESS';
export const VOICEMAIL_GET_EMPLOYEE_LIST_ERROR =
  'VOICEMAIL_GET_EMPLOYEE_LIST_ERROR';

/* Voicemail Drop */
export const VOICEMAIL_DROP_GET_LIST = 'VOICEMAIL_DROP_GET_LIST';
export const VOICEMAIL_DROP_GET_LIST_SUCCESS =
  'VOICEMAIL_DROP_GET_LIST_SUCCESS';
export const VOICEMAIL_DROP_GET_LIST_ERROR = 'VOICEMAIL_DROP_GET_LIST_ERROR';
export const VOICEMAIL_DROP_ADD_ITEM = 'VOICEMAIL_DROP_ADD_ITEM';
export const VOICEMAIL_DROP_ADD_ITEM_SUCCESS =
  'VOICEMAIL_DROP_ADD_ITEM_SUCCESS';
export const VOICEMAIL_DROP_ADD_ITEM_ERROR = 'VOICEMAIL_DROP_ADD_ITEM_ERROR';
export const VOICEMAIL_DROP_EDIT_ITEM = 'VOICEMAIL_DROP_EDIT_ITEM';
export const VOICEMAIL_DROP_EDIT_ITEM_SUCCESS =
  'VOICEMAIL_DROP_EDIT_ITEM_SUCCESS';
export const VOICEMAIL_DROP_EDIT_ITEM_ERROR = 'VOICEMAIL_DROP_EDIT_ITEM_ERROR';

/* CallForwardings */
export const CALL_FORWARDING_GET_LIST = 'CALL_FORWARDING_GET_LIST';
export const CALL_FORWARDING_GET_LIST_SUCCESS =
  'CALL_FORWARDING_GET_LIST_SUCCESS';
export const CALL_FORWARDING_GET_LIST_ERROR = 'CALL_FORWARDING_GET_LIST_ERROR';
export const CALL_FORWARDING_ADD_ITEM = 'CALL_FORWARDING_ADD_ITEM';
export const CALL_FORWARDING_ADD_ITEM_SUCCESS =
  'CALL_FORWARDING_ADD_ITEM_SUCCESS';
export const CALL_FORWARDING_ADD_ITEM_ERROR = 'CALL_FORWARDING_ADD_ITEM_ERROR';
export const CALL_FORWARDING_EDIT_ITEM = 'CALL_FORWARDING_EDIT_ITEM';
export const CALL_FORWARDING_EDIT_ITEM_SUCCESS =
  'CALL_FORWARDING_EDIT_ITEM_SUCCESS';
export const CALL_FORWARDING_EDIT_ITEM_ERROR =
  'CALL_FORWARDING_EDIT_ITEM_ERROR';
export const CALL_FORWARDING_GET_EMPLOYEE_LIST =
  'CALL_FORWARDING_GET_EMPLOYEE_LIST';
export const CALL_FORWARDING_GET_EMPLOYEE_LIST_SUCCESS =
  'CALL_FORWARDING_GET_EMPLOYEE_LIST_SUCCESS';
export const CALL_FORWARDING_GET_EMPLOYEE_LIST_ERROR =
  'CALL_FORWARDING_GET_EMPLOYEE_LIST_ERROR';

/* Robo List */
export const ROBO_GET_LIST = 'ROBO_GET_LIST';
export const ROBO_GET_LIST_SUCCESS = 'ROBO_GET_LIST_SUCCESS';
export const ROBO_GET_LIST_ERROR = 'ROBO_GET_LIST_ERROR';
export const ROBO_ADD_ITEM = 'ROBO_ADD_ITEM';
export const ROBO_ADD_ITEM_SUCCESS = 'ROBO_ADD_ITEM_SUCCESS';
export const ROBO_ADD_ITEM_ERROR = 'ROBO_ADD_ITEM_ERROR';
export const ROBO_GET_EMPLOYEE_LIST = 'ROBO_GET_EMPLOYEE_LIST';
export const ROBO_GET_EMPLOYEE_LIST_SUCCESS = 'ROBO_GET_EMPLOYEE_LIST_SUCCESS';
export const ROBO_GET_EMPLOYEE_LIST_ERROR = 'ROBO_GET_EMPLOYEE_LIST_ERROR';
export const ROBO_GET_NUMBERS_LIST = 'ROBO_GET_NUMBERS_LIST';
export const ROBO_GET_NUMBERS_LIST_SUCCESS = 'ROBO_GET_NUMBERS_LIST_SUCCESS';
export const ROBO_GET_NUMBERS_LIST_ERROR = 'ROBO_GET_NUMBERS_LIST_ERROR';

/* Progressive List */
export const PROGRESSIVE_GET_LIST = 'PROGRESSIVE_GET_LIST';
export const PROGRESSIVE_GET_LIST_SUCCESS = 'PROGRESSIVE_GET_LIST_SUCCESS';
export const PROGRESSIVE_GET_LIST_ERROR = 'PROGRESSIVE_GET_LIST_ERROR';
export const PROGRESSIVE_ADD_ITEM = 'PROGRESSIVE_ADD_ITEM';
export const PROGRESSIVE_ADD_ITEM_SUCCESS = 'PROGRESSIVE_ADD_ITEM_SUCCESS';
export const PROGRESSIVE_ADD_ITEM_ERROR = 'PROGRESSIVE_ADD_ITEM_ERROR';
export const PROGRESSIVE_GET_EMPLOYEE_LIST = 'PROGRESSIVE_GET_EMPLOYEE_LIST';
export const PROGRESSIVE_GET_EMPLOYEE_LIST_SUCCESS =
  'PROGRESSIVE_GET_EMPLOYEE_LIST_SUCCESS';
export const PROGRESSIVE_GET_EMPLOYEE_LIST_ERROR =
  'PROGRESSIVE_GET_EMPLOYEE_LIST_ERROR';
export const PROGRESSIVE_GET_NUMBERS_LIST = 'PROGRESSIVE_GET_NUMBERS_LIST';
export const PROGRESSIVE_GET_NUMBERS_LIST_SUCCESS =
  'PROGRESSIVE_GET_NUMBERS_LIST_SUCCESS';
export const PROGRESSIVE_GET_NUMBERS_LIST_ERROR =
  'PROGRESSIVE_GET_NUMBERS_LIST_ERROR';

/* Bulk SMS */
export const BULK_SMS_GET_LIST = 'BULK_SMS_GET_LIST';
export const BULK_SMS_GET_LIST_SUCCESS = 'BULK_SMS_GET_LIST_SUCCESS';
export const BULK_SMS_GET_LIST_ERROR = 'BULK_SMS_GET_LIST_ERROR';
export const BULK_SMS_ADD_ITEM = 'BULK_SMS_ADD_ITEM';
export const BULK_SMS_ADD_ITEM_SUCCESS = 'BULK_SMS_ADD_ITEM_SUCCESS';
export const BULK_SMS_ADD_ITEM_ERROR = 'BULK_SMS_ADD_ITEM_ERROR';
export const BULK_SMS_GET_NUMBERS_LIST = 'BULK_SMS_GET_NUMBERS_LIST';
export const BULK_SMS_GET_NUMBERS_LIST_SUCCESS =
  'BULK_SMS_GET_NUMBERS_LIST_SUCCESS';
export const BULK_SMS_GET_NUMBERS_LIST_ERROR =
  'BULK_SMS_GET_NUMBERS_LIST_ERROR';

/* CHAT APP */
export const CHAT_GET_CONTACTS = 'CHAT_GET_CONTACTS';
export const CHAT_GET_CONTACTS_SUCCESS = 'CHAT_GET_CONTACTS_SUCCESS';
export const CHAT_GET_CONTACTS_ERROR = 'CHAT_GET_CONTACTS_ERROR';
export const CHAT_GET_CONVERSATIONS = 'CHAT_GET_CONVERSATIONS';
export const CHAT_GET_CONVERSATIONS_SUCCESS = 'CHAT_GET_CONVERSATIONS_SUCCESS';
export const CHAT_GET_CONVERSATIONS_ERROR = 'CHAT_GET_CONVERSATIONS_ERROR';
export const CHAT_ADD_MESSAGE_TO_CONVERSATION =
  'CHAT_ADD_MESSAGE_TO_CONVERSATION';
export const CHAT_CREATE_CONVERSATION = 'CHAT_CREATE_CONVERSATION';
export const CHAT_SEARCH_CONTACT = 'CHAT_SEARCH_CONTACT';
export const CHAT_CHANGE_CONVERSATION = 'CHAT_CHANGE_CONVERSATION';

/* SURVEY LIST APP */
export const SURVEY_LIST_GET_LIST = 'SURVEY_LIST_GET_LIST';
export const SURVEY_LIST_GET_LIST_SUCCESS = 'SURVEY_LIST_GET_LIST_SUCCESS';
export const SURVEY_LIST_GET_LIST_ERROR = 'SURVEY_LIST_GET_LIST_ERROR';
export const SURVEY_LIST_GET_LIST_WITH_FILTER =
  'SURVEY_LIST_GET_LIST_WITH_FILTER';
export const SURVEY_LIST_GET_LIST_WITH_ORDER =
  'SURVEY_LIST_GET_LIST_WITH_ORDER';
export const SURVEY_LIST_GET_LIST_SEARCH = 'SURVEY_LIST_GET_LIST_SEARCH';
export const SURVEY_LIST_ADD_ITEM = 'SURVEY_LIST_ADD_ITEM';
export const SURVEY_LIST_ADD_ITEM_SUCCESS = 'SURVEY_LIST_ADD_ITEM_SUCCESS';
export const SURVEY_LIST_ADD_ITEM_ERROR = 'SURVEY_LIST_ADD_ITEM_ERROR';
export const SURVEY_LIST_SELECTED_ITEMS_CHANGE =
  'SURVEY_LIST_SELECTED_ITEMS_CHANGE';

/* SURVEY DETAIL APP */
export const SURVEY_GET_DETAILS = 'SURVEY_GET_DETAILS';
export const SURVEY_GET_DETAILS_SUCCESS = 'SURVEY_GET_DETAILS_SUCCESS';
export const SURVEY_GET_DETAILS_ERROR = 'SURVEY_GET_DETAILS_ERROR';
export const SURVEY_DELETE_QUESTION = 'SURVEY_DELETE_QUESTION';
export const SURVEY_SAVE = 'SURVEY_SAVE';

export * from './menu/actions';
export * from './settings/actions';
export * from './auth/actions';
export * from './todo/actions';
export * from './campaigns/actions';
export * from './queues/actions';
export * from './smsQueues/actions';
export * from './misscallBox/actions';
export * from './callbacks/actions';
export * from './ivr/actions';
export * from './timeConditions/actions';
export * from './voicemailDrop/actions';
export * from './voicemail/actions';
export * from './callForwarding/actions';
export * from './roboDialer/actions';
export * from './progressiveDialer/actions';
export * from './bulkSms/actions';
export * from './chat/actions';
export * from './surveyList/actions';
export * from './surveyDetail/actions';
