// eslint-disable-next-line import/no-cycle
import {
  VOICEMAIL_GET_LIST,
  VOICEMAIL_GET_LIST_SUCCESS,
  VOICEMAIL_GET_LIST_ERROR,
  VOICEMAIL_ADD_ITEM,
  VOICEMAIL_ADD_ITEM_SUCCESS,
  VOICEMAIL_ADD_ITEM_ERROR,
  VOICEMAIL_EDIT_ITEM,
  VOICEMAIL_EDIT_ITEM_SUCCESS,
  VOICEMAIL_EDIT_ITEM_ERROR,
  VOICEMAIL_GET_EMPLOYEE_LIST,
  VOICEMAIL_GET_EMPLOYEE_LIST_SUCCESS,
  VOICEMAIL_GET_EMPLOYEE_LIST_ERROR,
} from '../actions';

export const getVoicemailList = () => ({
  type: VOICEMAIL_GET_LIST,
});

export const getVoicemailListSuccess = (items) => ({
  type: VOICEMAIL_GET_LIST_SUCCESS,
  payload: items,
});

export const getVoicemailListError = (error) => ({
  type: VOICEMAIL_GET_LIST_ERROR,
  payload: error,
});

export const getVoicemailEmployeeLists = () => ({
  type: VOICEMAIL_GET_EMPLOYEE_LIST,
});

export const getVoicemailEmployeeListsSuccess = (items) => ({
  type: VOICEMAIL_GET_EMPLOYEE_LIST_SUCCESS,
  payload: items,
});

export const getVoicemailEmployeeListsError = (error) => ({
  type: VOICEMAIL_GET_EMPLOYEE_LIST_ERROR,
  payload: error,
});

export const addVoicemailItem = (item) => ({
  type: VOICEMAIL_ADD_ITEM,
  payload: item,
});

export const addVoicemailItemSuccess = (items) => ({
  type: VOICEMAIL_ADD_ITEM_SUCCESS,
  payload: items,
});

export const addVoicemailItemError = (error) => ({
  type: VOICEMAIL_ADD_ITEM_ERROR,
  payload: error,
});

export const editVoicemailItem = (item) => ({
  type: VOICEMAIL_EDIT_ITEM,
  payload: item,
});

export const editVoicemailItemSuccess = (items) => ({
  type: VOICEMAIL_EDIT_ITEM_SUCCESS,
  payload: items,
});

export const editVoicemailItemError = (error) => ({
  type: VOICEMAIL_EDIT_ITEM_ERROR,
  payload: error,
});
