import {
  QUEUES_GET_LIST,
  QUEUES_GET_LIST_SUCCESS,
  QUEUES_GET_LIST_ERROR,
  QUEUES_ADD_ITEM,
  QUEUES_ADD_ITEM_SUCCESS,
  QUEUES_ADD_ITEM_ERROR,
  QUEUES_EDIT_ITEM,
  QUEUES_EDIT_ITEM_SUCCESS,
  QUEUES_EDIT_ITEM_ERROR,
  QUEUES_GET_EMPLOYEE_LIST,
  QUEUES_GET_EMPLOYEE_LIST_ERROR,
  QUEUES_GET_EMPLOYEE_LIST_SUCCESS,
} from '../actions';

const INIT_STATE = {
  queues: [],
  employees: [],
  ringStrategies: [
    { name: 'roundRobin', label: 'Round Robin' },
    { name: 'sequential', label: 'Sequential' },
    { name: 'simultaneous', label: 'Simultaneous' },
  ],
  title: '',
  error: '',
  loading: false,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case QUEUES_GET_LIST:
      return { ...state, loading: false };

    case QUEUES_GET_LIST_SUCCESS:
      return {
        ...state,
        loading: true,
        queues: action.payload,
      };

    case QUEUES_GET_LIST_ERROR:
      return { ...state, loading: true, error: action.payload };

    case QUEUES_GET_EMPLOYEE_LIST:
      return { ...state, loading: false };

    case QUEUES_GET_EMPLOYEE_LIST_SUCCESS:
      return {
        ...state,
        loading: true,
        employees: action.payload,
      };

    case QUEUES_GET_EMPLOYEE_LIST_ERROR:
      return { ...state, loading: true, error: action.payload };

    case QUEUES_ADD_ITEM:
      return { ...state, loading: false };

    case QUEUES_ADD_ITEM_SUCCESS:
      if (action.payload === null) {
        return {
          ...state,
          loading: true,
        };
      }
      window.location.reload();
      return {
        ...state,
        loading: true,
        queues: action.payload,
      };

    case QUEUES_ADD_ITEM_ERROR:
      return { ...state, loading: true, error: action.payload };

    case QUEUES_EDIT_ITEM:
      return { ...state, loading: false };

    case QUEUES_EDIT_ITEM_SUCCESS:
      if (action.payload === null) {
        return {
          ...state,
          loading: true,
        };
      }
      window.location.reload();
      return {
        ...state,
        loading: true,
        queues: action.payload,
      };

    case QUEUES_EDIT_ITEM_ERROR:
      return { ...state, loading: true, error: action.payload };

    default:
      return { ...state };
  }
};
