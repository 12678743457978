// eslint-disable-next-line import/no-cycle
import {
  PROGRESSIVE_GET_LIST,
  PROGRESSIVE_GET_LIST_SUCCESS,
  PROGRESSIVE_GET_LIST_ERROR,
  PROGRESSIVE_ADD_ITEM,
  PROGRESSIVE_ADD_ITEM_SUCCESS,
  PROGRESSIVE_ADD_ITEM_ERROR,
  PROGRESSIVE_GET_EMPLOYEE_LIST,
  PROGRESSIVE_GET_EMPLOYEE_LIST_SUCCESS,
  PROGRESSIVE_GET_EMPLOYEE_LIST_ERROR,
  PROGRESSIVE_GET_NUMBERS_LIST,
  PROGRESSIVE_GET_NUMBERS_LIST_SUCCESS,
  PROGRESSIVE_GET_NUMBERS_LIST_ERROR,
} from '../actions';

export const getProgressiveList = () => ({
  type: PROGRESSIVE_GET_LIST,
});

export const getProgressiveListSuccess = (items) => ({
  type: PROGRESSIVE_GET_LIST_SUCCESS,
  payload: items,
});

export const getProgressiveListError = (error) => ({
  type: PROGRESSIVE_GET_LIST_ERROR,
  payload: error,
});

export const getProgressiveEmployeeLists = (destinationName) => ({
  type: PROGRESSIVE_GET_EMPLOYEE_LIST,
  payload: destinationName,
});

export const getProgressiveEmployeeListsSuccess = (items) => ({
  type: PROGRESSIVE_GET_EMPLOYEE_LIST_SUCCESS,
  payload: items,
});

export const getProgressiveEmployeeListsError = (error) => ({
  type: PROGRESSIVE_GET_EMPLOYEE_LIST_ERROR,
  payload: error,
});

export const getProgressiveNumbersLists = (destinationName) => ({
  type: PROGRESSIVE_GET_NUMBERS_LIST,
  payload: destinationName,
});

export const getProgressiveNumbersListsSuccess = (items) => ({
  type: PROGRESSIVE_GET_NUMBERS_LIST_SUCCESS,
  payload: items,
});

export const getProgressiveNumbersListsError = (error) => ({
  type: PROGRESSIVE_GET_NUMBERS_LIST_ERROR,
  payload: error,
});

export const addProgressiveItem = (item) => ({
  type: PROGRESSIVE_ADD_ITEM,
  payload: item,
});

export const addProgressiveItemSuccess = (items) => ({
  type: PROGRESSIVE_ADD_ITEM_SUCCESS,
  payload: items,
});

export const addProgressiveItemError = (error) => ({
  type: PROGRESSIVE_ADD_ITEM_ERROR,
  payload: error,
});
