// eslint-disable-next-line import/no-cycle
import {
  SMS_QUEUES_GET_LIST,
  SMS_QUEUES_GET_LIST_SUCCESS,
  SMS_QUEUES_GET_LIST_ERROR,
  SMS_QUEUES_ADD_ITEM,
  SMS_QUEUES_ADD_ITEM_SUCCESS,
  SMS_QUEUES_ADD_ITEM_ERROR,
  SMS_QUEUES_EDIT_ITEM,
  SMS_QUEUES_EDIT_ITEM_SUCCESS,
  SMS_QUEUES_EDIT_ITEM_ERROR,
  SMS_QUEUES_GET_EMPLOYEE_LIST,
  SMS_QUEUES_GET_EMPLOYEE_LIST_SUCCESS,
  SMS_QUEUES_GET_EMPLOYEE_LIST_ERROR,
} from '../actions';

export const getSmsQueuesList = () => ({
  type: SMS_QUEUES_GET_LIST,
});

export const getSmsQueuesListSuccess = (items) => ({
  type: SMS_QUEUES_GET_LIST_SUCCESS,
  payload: items,
});

export const getSmsQueuesListError = (error) => ({
  type: SMS_QUEUES_GET_LIST_ERROR,
  payload: error,
});

export const getSmsQueuesEmployeeLists = () => ({
  type: SMS_QUEUES_GET_EMPLOYEE_LIST,
});

export const getSmsQueuesEmployeeListsSuccess = (items) => ({
  type: SMS_QUEUES_GET_EMPLOYEE_LIST_SUCCESS,
  payload: items,
});

export const getSmsQueuesEmployeeListsError = (error) => ({
  type: SMS_QUEUES_GET_EMPLOYEE_LIST_ERROR,
  payload: error,
});

export const addSmsQueueItem = (item) => ({
  type: SMS_QUEUES_ADD_ITEM,
  payload: item,
});

export const addSmsQueueItemSuccess = (items) => ({
  type: SMS_QUEUES_ADD_ITEM_SUCCESS,
  payload: items,
});

export const addSmsQueueItemError = (error) => ({
  type: SMS_QUEUES_ADD_ITEM_ERROR,
  payload: error,
});

export const editSmsQueueItem = (item) => ({
  type: SMS_QUEUES_EDIT_ITEM,
  payload: item,
});

export const editSmsQueueItemSuccess = (items) => ({
  type: SMS_QUEUES_EDIT_ITEM_SUCCESS,
  payload: items,
});

export const editSmsQueueItemError = (error) => ({
  type: SMS_QUEUES_EDIT_ITEM_ERROR,
  payload: error,
});
