// eslint-disable-next-line import/no-cycle
import {
  CALL_FORWARDING_GET_LIST,
  CALL_FORWARDING_GET_LIST_SUCCESS,
  CALL_FORWARDING_GET_LIST_ERROR,
  CALL_FORWARDING_ADD_ITEM,
  CALL_FORWARDING_ADD_ITEM_SUCCESS,
  CALL_FORWARDING_ADD_ITEM_ERROR,
  CALL_FORWARDING_EDIT_ITEM,
  CALL_FORWARDING_EDIT_ITEM_SUCCESS,
  CALL_FORWARDING_EDIT_ITEM_ERROR,
  CALL_FORWARDING_GET_EMPLOYEE_LIST,
  CALL_FORWARDING_GET_EMPLOYEE_LIST_SUCCESS,
  CALL_FORWARDING_GET_EMPLOYEE_LIST_ERROR,
} from '../actions';

export const getCallForwardingList = () => ({
  type: CALL_FORWARDING_GET_LIST,
});

export const getCallForwardingListSuccess = (items) => ({
  type: CALL_FORWARDING_GET_LIST_SUCCESS,
  payload: items,
});

export const getCallForwardingListError = (error) => ({
  type: CALL_FORWARDING_GET_LIST_ERROR,
  payload: error,
});

export const getCallForwardingEmployeeLists = () => ({
  type: CALL_FORWARDING_GET_EMPLOYEE_LIST,
});

export const getCallForwardingEmployeeListsSuccess = (items) => ({
  type: CALL_FORWARDING_GET_EMPLOYEE_LIST_SUCCESS,
  payload: items,
});

export const getCallForwardingEmployeeListsError = (error) => ({
  type: CALL_FORWARDING_GET_EMPLOYEE_LIST_ERROR,
  payload: error,
});

export const addCallForwardingItem = (item) => ({
  type: CALL_FORWARDING_ADD_ITEM,
  payload: item,
});

export const addCallForwardingItemSuccess = (items) => ({
  type: CALL_FORWARDING_ADD_ITEM_SUCCESS,
  payload: items,
});

export const addCallForwardingItemError = (error) => ({
  type: CALL_FORWARDING_ADD_ITEM_ERROR,
  payload: error,
});

export const editCallForwardingItem = (item) => ({
  type: CALL_FORWARDING_EDIT_ITEM,
  payload: item,
});

export const editCallForwardingItemSuccess = (items) => ({
  type: CALL_FORWARDING_EDIT_ITEM_SUCCESS,
  payload: items,
});

export const editCallForwardingItemError = (error) => ({
  type: CALL_FORWARDING_EDIT_ITEM_ERROR,
  payload: error,
});
