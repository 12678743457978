import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import axios from 'axios';

import {
  VOICEMAIL_DROP_GET_LIST,
  VOICEMAIL_DROP_ADD_ITEM,
  VOICEMAIL_DROP_EDIT_ITEM,
} from '../actions';

import {
  getVoicemailDropListSuccess,
  getVoicemailDropListError,
  addVoicemailDropItemSuccess,
  addVoicemailDropItemError,
  editVoicemailDropItemSuccess,
  editVoicemailDropItemError,
} from './actions';

const getVoicemailDropListRequest = async () => {
  // eslint-disable-next-line no-return-await
  return await new Promise((success) => {
    axios
      .get('/flow/voicemailDrops/')
      .then((response) => success(Object.values(response?.data?.payload)))
      .catch(() => success([]));
  })
    .then((response) => response)
    .catch((error) => error);
};

function* getVoicemailDropListItems() {
  try {
    const response = yield call(getVoicemailDropListRequest);
    yield put(getVoicemailDropListSuccess(response));
  } catch (error) {
    yield put(getVoicemailDropListError(error));
  }
}

const addVoicemailDropItemRequest = async (item) => {
  // eslint-disable-next-line no-return-await
  return new Promise((success) => {
    axios
      .post('/flow/voicemailDrop', { ...item })
      .then((response) => success(response.data.voicemailDrops))
      .catch(() => success(null));
  })
    .then((response) => response)
    .catch((error) => error);
};

function* addVoicemailDropItem({ payload }) {
  try {
    const response = yield call(addVoicemailDropItemRequest, payload);
    yield put(addVoicemailDropItemSuccess(response));
  } catch (error) {
    yield put(addVoicemailDropItemError(error));
  }
}

const editVoicemailDropItemRequest = async (item) => {
  // eslint-disable-next-line no-return-await
  return new Promise((success) => {
    axios
      .put(`/flow/voicemailDrop/${item.voicemailDropId}`, { ...item })
      .then((response) => success(response.data.voicemailDrops))
      .catch(() => success(null));
  })
    .then((response) => response)
    .catch((error) => error);
};

function* editVoicemailDropItem({ payload }) {
  try {
    const response = yield call(editVoicemailDropItemRequest, payload);
    yield put(editVoicemailDropItemSuccess(response));
  } catch (error) {
    yield put(editVoicemailDropItemError(error));
  }
}

export function* watchGetList() {
  yield takeEvery(VOICEMAIL_DROP_GET_LIST, getVoicemailDropListItems);
}

export function* wathcAddItem() {
  yield takeEvery(VOICEMAIL_DROP_ADD_ITEM, addVoicemailDropItem);
}

export function* wathcEditItem() {
  yield takeEvery(VOICEMAIL_DROP_EDIT_ITEM, editVoicemailDropItem);
}

export default function* rootSaga() {
  yield all([fork(watchGetList), fork(wathcAddItem), fork(wathcEditItem)]);
}
