import { all } from 'redux-saga/effects';
import authSagas from './auth/saga';
import todoSagas from './todo/saga';
import queuesSagas from './queues/saga';
import smsQueuesSagas from './smsQueues/saga';
import misscallBoxSagas from './misscallBox/saga';
import callbacksSagas from './callbacks/saga';
import timeConditionsSagas from './timeConditions/saga';
import ivrSagas from './ivr/saga';
import voicemailSagas from './voicemail/saga';
import voicemailDropSagas from './voicemailDrop/saga';
import callForwardingSagas from './callForwarding/saga';
import roboDialerSagas from './roboDialer/saga';
import progressiveDialerSagas from './progressiveDialer/saga';
import campaignsSagas from './campaigns/saga';
import bulkSmsSagas from './bulkSms/saga';
import chatSagas from './chat/saga';
import surveyListSagas from './surveyList/saga';
import surveyDetailSagas from './surveyDetail/saga';

export default function* rootSaga() {
  yield all([
    authSagas(),
    todoSagas(),
    queuesSagas(),
    smsQueuesSagas(),
    misscallBoxSagas(),
    callbacksSagas(),
    campaignsSagas(),
    timeConditionsSagas(),
    ivrSagas(),
    voicemailSagas(),
    voicemailDropSagas(),
    callForwardingSagas(),
    roboDialerSagas(),
    progressiveDialerSagas(),
    bulkSmsSagas(),
    chatSagas(),
    surveyListSagas(),
    surveyDetailSagas(),
  ]);
}
