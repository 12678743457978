import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import axios from 'axios';

import {
  CALL_FORWARDING_GET_LIST,
  CALL_FORWARDING_ADD_ITEM,
  CALL_FORWARDING_EDIT_ITEM,
  CALL_FORWARDING_GET_EMPLOYEE_LIST,
} from '../actions';

import {
  getCallForwardingListSuccess,
  getCallForwardingListError,
  addCallForwardingItemSuccess,
  addCallForwardingItemError,
  editCallForwardingItemSuccess,
  editCallForwardingItemError,
  getCallForwardingEmployeeListsSuccess,
  getCallForwardingEmployeeListsError,
} from './actions';

const getCallForwardingEmployeeListRequest = async () => {
  // eslint-disable-next-line no-return-await
  return await new Promise((success) => {
    axios
      .get('/user/getSipUsers/')
      .then((response) => success(response?.data?.payload))
      .catch(() => success([]));
  })
    .then((response) => response)
    .catch((error) => error);
};

function* getCallForwardingEmployeeListItems() {
  try {
    const response = yield call(getCallForwardingEmployeeListRequest);
    yield put(getCallForwardingEmployeeListsSuccess(response));
  } catch (error) {
    yield put(getCallForwardingEmployeeListsError(error));
  }
}

const getCallForwardingListRequest = async () => {
  // eslint-disable-next-line no-return-await
  return await new Promise((success) => {
    axios
      .get('/flow/callForwardings/')
      .then((response) => success(Object.values(response?.data?.payload)))
      .catch(() => success([]));
  })
    .then((response) => response)
    .catch((error) => error);
};

function* getCallForwardingListItems() {
  try {
    const response = yield call(getCallForwardingListRequest);
    yield put(getCallForwardingListSuccess(response));
  } catch (error) {
    yield put(getCallForwardingListError(error));
  }
}

const addCallForwardingItemRequest = async (item) => {
  // eslint-disable-next-line no-return-await
  return new Promise((success) => {
    axios
      .post('/flow/callForwarding', { ...item })
      .then((response) => success(response.data.callForwardings))
      .catch(() => success(null));
  })
    .then((response) => response)
    .catch((error) => error);
};

function* addCallForwardingItem({ payload }) {
  try {
    const response = yield call(addCallForwardingItemRequest, payload);
    yield put(addCallForwardingItemSuccess(response));
  } catch (error) {
    yield put(addCallForwardingItemError(error));
  }
}

const editCallForwardingItemRequest = async (item) => {
  // eslint-disable-next-line no-return-await
  return new Promise((success) => {
    axios
      .put(`/flow/callForwarding/${item.callForwardingId}`, { ...item })
      .then((response) => success(response.data.callForwardings))
      .catch(() => success(null));
  })
    .then((response) => response)
    .catch((error) => error);
};

function* editCallForwardingItem({ payload }) {
  try {
    const response = yield call(editCallForwardingItemRequest, payload);
    yield put(editCallForwardingItemSuccess(response));
  } catch (error) {
    yield put(editCallForwardingItemError(error));
  }
}

export function* watchGetList() {
  yield takeEvery(CALL_FORWARDING_GET_LIST, getCallForwardingListItems);
}

export function* watchGetCallForwardingEmployeeList() {
  yield takeEvery(
    CALL_FORWARDING_GET_EMPLOYEE_LIST,
    getCallForwardingEmployeeListItems
  );
}

export function* wathcAddItem() {
  yield takeEvery(CALL_FORWARDING_ADD_ITEM, addCallForwardingItem);
}

export function* wathcEditItem() {
  yield takeEvery(CALL_FORWARDING_EDIT_ITEM, editCallForwardingItem);
}

export default function* rootSaga() {
  yield all([
    fork(watchGetList),
    fork(wathcAddItem),
    fork(wathcEditItem),
    fork(watchGetCallForwardingEmployeeList),
  ]);
}
