// eslint-disable-next-line import/no-cycle
import {
  IVR_GET_LIST,
  IVR_GET_LIST_SUCCESS,
  IVR_GET_LIST_ERROR,
  IVR_ADD_ITEM,
  IVR_ADD_ITEM_SUCCESS,
  IVR_ADD_ITEM_ERROR,
  IVR_EDIT_ITEM,
  IVR_EDIT_ITEM_SUCCESS,
  IVR_EDIT_ITEM_ERROR,
  IVR_FAIL_OVER_DESTINATIONS_LIST,
  IVR_FAIL_OVER_DESTINATIONS_LIST_SUCCESS,
  IVR_FAIL_OVER_DESTINATIONS_LIST_ERROR,
} from '../actions';

export const getIVRList = () => ({
  type: IVR_GET_LIST,
});

export const getIVRListSuccess = (items) => ({
  type: IVR_GET_LIST_SUCCESS,
  payload: items,
});

export const getIVRListError = (error) => ({
  type: IVR_GET_LIST_ERROR,
  payload: error,
});

export const getIVRFailOverDestinations = (destinationName) => ({
  type: IVR_FAIL_OVER_DESTINATIONS_LIST,
  payload: destinationName,
});

export const getIVRFailOverDestinationsSuccess = (items) => ({
  type: IVR_FAIL_OVER_DESTINATIONS_LIST_SUCCESS,
  payload: items,
});

export const getIVRFailOverDestinationsError = (error) => ({
  type: IVR_FAIL_OVER_DESTINATIONS_LIST_ERROR,
  payload: error,
});

export const addIVRItem = (item) => ({
  type: IVR_ADD_ITEM,
  payload: item,
});

export const addIVRItemSuccess = (items) => ({
  type: IVR_ADD_ITEM_SUCCESS,
  payload: items,
});

export const addIVRItemError = (error) => ({
  type: IVR_ADD_ITEM_ERROR,
  payload: error,
});

export const editIVRItem = (item) => ({
  type: IVR_EDIT_ITEM,
  payload: item,
});

export const editIVRItemSuccess = (items) => ({
  type: IVR_EDIT_ITEM_SUCCESS,
  payload: items,
});

export const editIVRItemError = (error) => ({
  type: IVR_EDIT_ITEM_ERROR,
  payload: error,
});
