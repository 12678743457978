import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import axios from 'axios';

import { auth } from '../../helpers/Firebase';

import {
  LOGIN_USER,
  REGISTER_USER,
  LOGOUT_USER,
  FORGOT_PASSWORD,
  RESET_PASSWORD,
  EMPLOYEE_RESET_PASSWORD,
} from '../actions';

import {
  loginUserSuccess,
  loginUserError,
  registerUserSuccess,
  registerUserError,
  forgotPasswordSuccess,
  forgotPasswordError,
  resetPasswordSuccess,
  resetPasswordError,
  employeeResetPasswordSuccess,
  employeeResetPasswordError,
} from './actions';

import { adminRoot, baseUrl, currentUser } from '../../constants/defaultValues';
import { setCurrentUser } from '../../helpers/Utils';

export function* watchLoginUser() {
  // eslint-disable-next-line no-use-before-define
  yield takeEvery(LOGIN_USER, loginWithEmailPassword);
}

const loginWithEmailPasswordAsync = async (email, password, type, domain) =>
  // eslint-disable-next-line no-return-await
  await axios
    .post(`${baseUrl}/auth/login`, { email, password, type, domain })
    .then((loginResponse) => loginResponse.data)
    .catch((error) => error.response?.data);

function* loginWithEmailPassword({ payload }) {
  const { email, password, type, domain } = payload.user;
  const { history } = payload;
  try {
    const loginUser = yield call(
      loginWithEmailPasswordAsync,
      email,
      password,
      type,
      domain
    );
    if (!loginUser.message) {
      const { token, fullName, userId, role } = loginUser;
      const item = {
        uid: userId,
        id: userId,
        token,
        role: role === 'admin' ? 0 : 1,
        title: fullName,
        ...currentUser,
      };
      setCurrentUser(item);
      yield put(loginUserSuccess(item));
      history.push(adminRoot);
    } else {
      yield put(loginUserError(loginUser.message));
    }
  } catch (error) {
    yield put(loginUserError(error));
  }
}

export function* watchRegisterUser() {
  // eslint-disable-next-line no-use-before-define
  yield takeEvery(REGISTER_USER, registerWithEmailPassword);
}

const registerWithEmailPasswordAsync = async (email, password) =>
  // eslint-disable-next-line no-return-await
  await auth
    .createUserWithEmailAndPassword(email, password)
    .then((user) => user)
    .catch((error) => error.response?.data);

function* registerWithEmailPassword({ payload }) {
  const { email, password } = payload.user;
  const { history } = payload;
  try {
    const registerUser = yield call(
      registerWithEmailPasswordAsync,
      email,
      password
    );
    if (!registerUser.message) {
      const item = { uid: registerUser.user.uid, ...currentUser };
      setCurrentUser(item);
      yield put(registerUserSuccess(item));
      history.push(adminRoot);
    } else {
      yield put(registerUserError(registerUser.message));
    }
  } catch (error) {
    yield put(registerUserError(error));
  }
}

export function* watchLogoutUser() {
  // eslint-disable-next-line no-use-before-define
  yield takeEvery(LOGOUT_USER, logout);
}

const logoutAsync = async (history) => {
  await auth
    .signOut()
    .then((user) => user)
    .catch((error) => error);
  history.push(adminRoot);
};

function* logout({ payload }) {
  const { history } = payload;
  setCurrentUser();
  yield call(logoutAsync, history);
}

export function* watchForgotPassword() {
  // eslint-disable-next-line no-use-before-define
  yield takeEvery(FORGOT_PASSWORD, forgotPassword);
}

const forgotPasswordAsync = async (email) => {
  // eslint-disable-next-line no-return-await
  return await new Promise((success, reject) => {
    axios
      .post(`${baseUrl}/auth/forgotPassword`, { email })
      .then(() => success())
      .catch((error) => reject(error?.response.data));
  })
    .then((response) => response)
    .catch((error) => error);
};

function* forgotPassword({ payload }) {
  const { email } = payload.forgotUserMail;
  try {
    const forgotPasswordStatus = yield call(forgotPasswordAsync, email);
    if (!forgotPasswordStatus) {
      yield put(forgotPasswordSuccess('success'));
    } else {
      yield put(forgotPasswordError(forgotPasswordStatus.message));
    }
  } catch (error) {
    yield put(forgotPasswordError(error));
  }
}

export function* watchResetPassword() {
  // eslint-disable-next-line no-use-before-define
  yield takeEvery(RESET_PASSWORD, resetPassword);
}

const resetPasswordAsync = async (resetPasswordCode, newPassword) => {
  // eslint-disable-next-line no-return-await
  await axios
    .post(`${baseUrl}/auth/resetPassword`, { resetPasswordCode, newPassword })
    .then((forgotPasswordResponse) => {
      if (forgotPasswordResponse.data?.success) {
        document.location.href = '/';
      }
    })
    .catch((error) => error.response?.data);
};

function* resetPassword({ payload }) {
  const { newPassword, resetPasswordCode } = payload;
  try {
    const resetPasswordStatus = yield call(
      resetPasswordAsync,
      resetPasswordCode,
      newPassword
    );
    if (!resetPasswordStatus) {
      yield put(resetPasswordSuccess('success'));
    } else {
      yield put(resetPasswordError(resetPasswordStatus.message));
    }
  } catch (error) {
    yield put(resetPasswordError(error));
  }
}

export function* watchEmployeeResetPassword() {
  // eslint-disable-next-line no-use-before-define
  yield takeEvery(EMPLOYEE_RESET_PASSWORD, employeeResetPassword);
}

const employeeResetPasswordAsync = async (
  employeeResetPasswordCode,
  newEmployeePassword
) => {
  // eslint-disable-next-line no-return-await
  await axios
    .post(`${baseUrl}/auth/resetDialerPassword`, {
      employeeResetPasswordCode,
      newEmployeePassword,
    })
    .then((forgotPasswordResponse) => {
      if (forgotPasswordResponse.data?.success) {
        document.location.href = '/';
      }
    })
    .catch((error) => error.response?.data);
};

function* employeeResetPassword({ payload }) {
  const { newEmployeePassword, employeeResetPasswordCode } = payload;
  try {
    const employeeResetPasswordStatus = yield call(
      employeeResetPasswordAsync,
      employeeResetPasswordCode,
      newEmployeePassword
    );
    if (!employeeResetPasswordStatus) {
      yield put(employeeResetPasswordSuccess('success'));
    } else {
      yield put(
        employeeResetPasswordError(employeeResetPasswordStatus.message)
      );
    }
  } catch (error) {
    yield put(employeeResetPasswordError(error));
  }
}

export default function* rootSaga() {
  yield all([
    fork(watchLoginUser),
    fork(watchLogoutUser),
    fork(watchRegisterUser),
    fork(watchForgotPassword),
    fork(watchResetPassword),
    fork(watchEmployeeResetPassword),
  ]);
}
