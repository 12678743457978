import {
  VOICEMAIL_DROP_GET_LIST,
  VOICEMAIL_DROP_GET_LIST_SUCCESS,
  VOICEMAIL_DROP_GET_LIST_ERROR,
  VOICEMAIL_DROP_ADD_ITEM,
  VOICEMAIL_DROP_ADD_ITEM_SUCCESS,
  VOICEMAIL_DROP_ADD_ITEM_ERROR,
  VOICEMAIL_DROP_EDIT_ITEM,
  VOICEMAIL_DROP_EDIT_ITEM_SUCCESS,
  VOICEMAIL_DROP_EDIT_ITEM_ERROR,
} from '../actions';

const INIT_STATE = {
  voicemailDrops: [],
  recordingType: '',
  textRecording: '',
  fileRecording: '',
  title: '',
  description: '',
  error: '',
  loading: false,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case VOICEMAIL_DROP_GET_LIST:
      return { ...state, loading: false };

    case VOICEMAIL_DROP_GET_LIST_SUCCESS:
      return {
        ...state,
        loading: true,
        voicemailDrops: action.payload,
      };

    case VOICEMAIL_DROP_GET_LIST_ERROR:
      return { ...state, loading: true, error: action.payload };

    case VOICEMAIL_DROP_ADD_ITEM:
      return { ...state, loading: false };

    case VOICEMAIL_DROP_ADD_ITEM_SUCCESS:
      if (action.payload === null) {
        return {
          ...state,
          loading: true,
        };
      }
      window.location.reload();
      return {
        ...state,
        loading: true,
        voicemailDrops: action.payload,
      };

    case VOICEMAIL_DROP_ADD_ITEM_ERROR:
      return { ...state, loading: true, error: action.payload };

    case VOICEMAIL_DROP_EDIT_ITEM:
      return { ...state, loading: false };

    case VOICEMAIL_DROP_EDIT_ITEM_SUCCESS:
      if (action.payload === null) {
        return {
          ...state,
          loading: true,
        };
      }
      window.location.reload();
      return {
        ...state,
        loading: true,
        voicemailDrops: action.payload,
      };

    case VOICEMAIL_DROP_EDIT_ITEM_ERROR:
      return { ...state, loading: true, error: action.payload };

    default:
      return { ...state };
  }
};
