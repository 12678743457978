// eslint-disable-next-line import/no-cycle
import {
  BULK_SMS_GET_LIST,
  BULK_SMS_GET_LIST_SUCCESS,
  BULK_SMS_GET_LIST_ERROR,
  BULK_SMS_ADD_ITEM,
  BULK_SMS_ADD_ITEM_SUCCESS,
  BULK_SMS_ADD_ITEM_ERROR,
  BULK_SMS_GET_NUMBERS_LIST,
  BULK_SMS_GET_NUMBERS_LIST_SUCCESS,
  BULK_SMS_GET_NUMBERS_LIST_ERROR,
} from '../actions';

export const getBulkSmsList = () => ({
  type: BULK_SMS_GET_LIST,
});

export const getBulkSmsListSuccess = (items) => ({
  type: BULK_SMS_GET_LIST_SUCCESS,
  payload: items,
});

export const getBulkSmsListError = (error) => ({
  type: BULK_SMS_GET_LIST_ERROR,
  payload: error,
});

export const getBulkSmsNumbersLists = (destinationName) => ({
  type: BULK_SMS_GET_NUMBERS_LIST,
  payload: destinationName,
});

export const getBulkSmsNumbersListsSuccess = (items) => ({
  type: BULK_SMS_GET_NUMBERS_LIST_SUCCESS,
  payload: items,
});

export const getBulkSmsNumbersListsError = (error) => ({
  type: BULK_SMS_GET_NUMBERS_LIST_ERROR,
  payload: error,
});

export const addBulkSmsItem = (item) => ({
  type: BULK_SMS_ADD_ITEM,
  payload: item,
});

export const addBulkSmsItemSuccess = (items) => ({
  type: BULK_SMS_ADD_ITEM_SUCCESS,
  payload: items,
});

export const addBulkSmsItemError = (error) => ({
  type: BULK_SMS_ADD_ITEM_ERROR,
  payload: error,
});
