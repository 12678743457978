// eslint-disable-next-line import/no-cycle
import {
  TIME_CONDITIONS_GET_LIST,
  TIME_CONDITIONS_GET_LIST_SUCCESS,
  TIME_CONDITIONS_GET_LIST_ERROR,
  TIME_CONDITIONS_ADD_ITEM,
  TIME_CONDITIONS_ADD_ITEM_SUCCESS,
  TIME_CONDITIONS_ADD_ITEM_ERROR,
  TIME_CONDITIONS_EDIT_ITEM,
  TIME_CONDITIONS_EDIT_ITEM_SUCCESS,
  TIME_CONDITIONS_EDIT_ITEM_ERROR,
  TIMECONDITION_DESTINATIONS_LIST,
  TIMECONDITION_DESTINATIONS_LIST_SUCCESS,
  TIMECONDITION_DESTINATIONS_LIST_ERROR,
  TIMECONDITION_NON_DESTINATIONS_LIST,
  TIMECONDITION_NON_DESTINATIONS_LIST_SUCCESS,
  TIMECONDITION_NON_DESTINATIONS_LIST_ERROR,
} from '../actions';

export const getTimeConditionsList = () => ({
  type: TIME_CONDITIONS_GET_LIST,
});

export const getTimeConditionsListSuccess = (items) => ({
  type: TIME_CONDITIONS_GET_LIST_SUCCESS,
  payload: items,
});

export const getTimeConditionsListError = (error) => ({
  type: TIME_CONDITIONS_GET_LIST_ERROR,
  payload: error,
});

export const getTimeConditionDestinations = (destinationName) => ({
  type: TIMECONDITION_DESTINATIONS_LIST,
  payload: destinationName,
});

export const getTimeConditionDestinationsSuccess = (items) => ({
  type: TIMECONDITION_DESTINATIONS_LIST_SUCCESS,
  payload: items,
});

export const getTimeConditionDestinationsError = (error) => ({
  type: TIMECONDITION_DESTINATIONS_LIST_ERROR,
  payload: error,
});

export const getTimeConditionNonDestinations = (destinationName) => ({
  type: TIMECONDITION_NON_DESTINATIONS_LIST,
  payload: destinationName,
});

export const getTimeConditionNonDestinationsSuccess = (items) => ({
  type: TIMECONDITION_NON_DESTINATIONS_LIST_SUCCESS,
  payload: items,
});

export const getTimeConditionNonDestinationsError = (error) => ({
  type: TIMECONDITION_NON_DESTINATIONS_LIST_ERROR,
  payload: error,
});

export const addTimeConditionsItem = (item) => ({
  type: TIME_CONDITIONS_ADD_ITEM,
  payload: item,
});

export const addTimeConditionsItemSuccess = (items) => ({
  type: TIME_CONDITIONS_ADD_ITEM_SUCCESS,
  payload: items,
});

export const addTimeConditionsItemError = (error) => ({
  type: TIME_CONDITIONS_ADD_ITEM_ERROR,
  payload: error,
});

export const editTimeConditionsItem = (item) => ({
  type: TIME_CONDITIONS_EDIT_ITEM,
  payload: item,
});

export const editTimeConditionsItemSuccess = (items) => ({
  type: TIME_CONDITIONS_EDIT_ITEM_SUCCESS,
  payload: items,
});

export const editTimeConditionsItemError = (error) => ({
  type: TIME_CONDITIONS_EDIT_ITEM_ERROR,
  payload: error,
});
