import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import axios from 'axios';

import {
  VOICEMAIL_GET_LIST,
  VOICEMAIL_ADD_ITEM,
  VOICEMAIL_EDIT_ITEM,
  VOICEMAIL_GET_EMPLOYEE_LIST,
} from '../actions';

import {
  getVoicemailListSuccess,
  getVoicemailListError,
  addVoicemailItemSuccess,
  addVoicemailItemError,
  editVoicemailItemSuccess,
  editVoicemailItemError,
  getVoicemailEmployeeListsSuccess,
  getVoicemailEmployeeListsError,
} from './actions';

const getVoicemailEmployeeListRequest = async () => {
  // eslint-disable-next-line no-return-await
  return await new Promise((success) => {
    axios
      .get('/user/getSipUsers/')
      .then((response) => success(response?.data?.payload))
      .catch(() => success([]));
  })
    .then((response) => response)
    .catch((error) => error);
};

function* getVoicemailEmployeeListItems() {
  try {
    const response = yield call(getVoicemailEmployeeListRequest);
    yield put(getVoicemailEmployeeListsSuccess(response));
  } catch (error) {
    yield put(getVoicemailEmployeeListsError(error));
  }
}

const getVoicemailListRequest = async () => {
  // eslint-disable-next-line no-return-await
  return await new Promise((success) => {
    axios
      .get('/flow/voicemails/')
      .then((response) => success(Object.values(response?.data?.payload)))
      .catch(() => success([]));
  })
    .then((response) => response)
    .catch((error) => error);
};

function* getVoicemailListItems() {
  try {
    const response = yield call(getVoicemailListRequest);
    yield put(getVoicemailListSuccess(response));
  } catch (error) {
    yield put(getVoicemailListError(error));
  }
}

const addVoicemailItemRequest = async (item) => {
  // eslint-disable-next-line no-return-await
  return new Promise((success) => {
    axios
      .post('/flow/voicemail', { ...item })
      .then((response) => success(response.data.voicemails))
      .catch(() => success(null));
  })
    .then((response) => response)
    .catch((error) => error);
};

function* addVoicemailItem({ payload }) {
  try {
    const response = yield call(addVoicemailItemRequest, payload);
    yield put(addVoicemailItemSuccess(response));
  } catch (error) {
    yield put(addVoicemailItemError(error));
  }
}

const editVoicemailItemRequest = async (item) => {
  // eslint-disable-next-line no-return-await
  return new Promise((success) => {
    axios
      .put(`/flow/voicemail/${item.voicemailId}`, { ...item })
      .then((response) => success(response.data.voicemails))
      .catch(() => success(null));
  })
    .then((response) => response)
    .catch((error) => error);
};

function* editVoicemailItem({ payload }) {
  try {
    const response = yield call(editVoicemailItemRequest, payload);
    yield put(editVoicemailItemSuccess(response));
  } catch (error) {
    yield put(editVoicemailItemError(error));
  }
}

export function* watchGetList() {
  yield takeEvery(VOICEMAIL_GET_LIST, getVoicemailListItems);
}

export function* watchGetVoicemailEmployeeList() {
  yield takeEvery(VOICEMAIL_GET_EMPLOYEE_LIST, getVoicemailEmployeeListItems);
}

export function* wathcAddItem() {
  yield takeEvery(VOICEMAIL_ADD_ITEM, addVoicemailItem);
}

export function* wathcEditItem() {
  yield takeEvery(VOICEMAIL_EDIT_ITEM, editVoicemailItem);
}

export default function* rootSaga() {
  yield all([
    fork(watchGetList),
    fork(wathcAddItem),
    fork(watchGetVoicemailEmployeeList),
    fork(wathcEditItem),
  ]);
}
