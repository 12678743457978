// eslint-disable-next-line import/no-cycle
import {
  MISS_CALL_BOX_GET_LIST,
  MISS_CALL_BOX_GET_LIST_SUCCESS,
  MISS_CALL_BOX_GET_LIST_ERROR,
  MISS_CALL_BOX_ADD_ITEM,
  MISS_CALL_BOX_ADD_ITEM_SUCCESS,
  MISS_CALL_BOX_ADD_ITEM_ERROR,
  MISS_CALL_BOX_EDIT_ITEM,
  MISS_CALL_BOX_EDIT_ITEM_SUCCESS,
  MISS_CALL_BOX_EDIT_ITEM_ERROR,
  MISS_CALL_BOX_GET_EMPLOYEE_LIST,
  MISS_CALL_BOX_GET_EMPLOYEE_LIST_SUCCESS,
  MISS_CALL_BOX_GET_EMPLOYEE_LIST_ERROR,
  MISS_CALL_BOX_GET_NUMBER_LIST,
  MISS_CALL_BOX_GET_NUMBER_LIST_SUCCESS,
  MISS_CALL_BOX_GET_NUMBER_LIST_ERROR,
} from '../actions';

export const getMisscallBoxList = () => ({
  type: MISS_CALL_BOX_GET_LIST,
});

export const getMisscallBoxListSuccess = (items) => ({
  type: MISS_CALL_BOX_GET_LIST_SUCCESS,
  payload: items,
});

export const getMisscallBoxListError = (error) => ({
  type: MISS_CALL_BOX_GET_LIST_ERROR,
  payload: error,
});

export const getMisscallBoxEmployeeLists = () => ({
  type: MISS_CALL_BOX_GET_EMPLOYEE_LIST,
});

export const getMisscallBoxEmployeeListsSuccess = (items) => ({
  type: MISS_CALL_BOX_GET_EMPLOYEE_LIST_SUCCESS,
  payload: items,
});

export const getMisscallBoxEmployeeListsError = (error) => ({
  type: MISS_CALL_BOX_GET_EMPLOYEE_LIST_ERROR,
  payload: error,
});

export const getMisscallBoxNumberLists = () => ({
  type: MISS_CALL_BOX_GET_NUMBER_LIST,
});

export const getMisscallBoxNumberListsSuccess = (items) => ({
  type: MISS_CALL_BOX_GET_NUMBER_LIST_SUCCESS,
  payload: items,
});

export const getMisscallBoxNumberListsError = (error) => ({
  type: MISS_CALL_BOX_GET_NUMBER_LIST_ERROR,
  payload: error,
});

export const addMisscallBoxItem = (item) => ({
  type: MISS_CALL_BOX_ADD_ITEM,
  payload: item,
});

export const addMisscallBoxItemSuccess = (items) => ({
  type: MISS_CALL_BOX_ADD_ITEM_SUCCESS,
  payload: items,
});

export const addMisscallBoxItemError = (error) => ({
  type: MISS_CALL_BOX_ADD_ITEM_ERROR,
  payload: error,
});

export const editMisscallBoxItem = (item) => ({
  type: MISS_CALL_BOX_EDIT_ITEM,
  payload: item,
});

export const editMisscallBoxItemSuccess = (items) => ({
  type: MISS_CALL_BOX_EDIT_ITEM_SUCCESS,
  payload: items,
});

export const editMisscallBoxItemError = (error) => ({
  type: MISS_CALL_BOX_EDIT_ITEM_ERROR,
  payload: error,
});
