// eslint-disable-next-line import/no-cycle
import {
  CAMPAIGN_GET_LIST,
  CAMPAIGN_GET_LIST_SUCCESS,
  CAMPAIGN_GET_LIST_ERROR,
  CAMPAIGN_ADD_ITEM,
  CAMPAIGN_ADD_ITEM_SUCCESS,
  CAMPAIGN_ADD_ITEM_ERROR,
} from '../actions';

export const getCampaignsList = () => ({
  type: CAMPAIGN_GET_LIST,
});

export const getCampaignsListSuccess = (items) => ({
  type: CAMPAIGN_GET_LIST_SUCCESS,
  payload: items,
});

export const getCampaignsListError = (error) => ({
  type: CAMPAIGN_GET_LIST_ERROR,
  payload: error,
});

export const addCampaignItem = (item) => ({
  type: CAMPAIGN_ADD_ITEM,
  payload: item,
});

export const addCampaignItemSuccess = (items) => ({
  type: CAMPAIGN_ADD_ITEM_SUCCESS,
  payload: items,
});

export const addCampaignItemError = (error) => ({
  type: CAMPAIGN_ADD_ITEM_ERROR,
  payload: error,
});
