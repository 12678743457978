import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import axios from 'axios';

import { CAMPAIGN_GET_LIST, CAMPAIGN_ADD_ITEM } from '../actions';

import {
  getCampaignsListSuccess,
  getCampaignsListError,
  addCampaignItemSuccess,
  addCampaignItemError,
} from './actions';

const getCampaignsListRequest = async () => {
  // eslint-disable-next-line no-return-await
  return await new Promise((success) => {
    axios
      .get('flow/campaigns/')
      .then((response) => success(Object.values(response?.data?.payload || [])))
      .catch(() => success([]));
  })
    .then((response) => response)
    .catch((error) => error);
};

function* getCampaignsListItems() {
  try {
    const response = yield call(getCampaignsListRequest);
    yield put(getCampaignsListSuccess(response));
  } catch (error) {
    yield put(getCampaignsListError(error));
  }
}

const addCampaignItemRequest = async (item) => {
  // eslint-disable-next-line no-return-await
  return await new Promise((success) => {
    axios
      .post('subscription/addCampaign', { ...item })
      .then((response) => success(response.data?.campaigns || []))
      .catch(() => success(null));
  })
    .then((response) => response)
    .catch((error) => error);
};

function* addCampaignItem({ payload }) {
  try {
    const response = yield call(addCampaignItemRequest, payload);
    yield put(addCampaignItemSuccess(response));
  } catch (error) {
    yield put(addCampaignItemError(error));
  }
}

export function* watchGetList() {
  yield takeEvery(CAMPAIGN_GET_LIST, getCampaignsListItems);
}

export function* wathcAddItem() {
  yield takeEvery(CAMPAIGN_ADD_ITEM, addCampaignItem);
}

export default function* rootSaga() {
  yield all([fork(watchGetList), fork(wathcAddItem)]);
}
