import { defaultDestinationNames } from '../../helpers/Utils';
import {
  TIME_CONDITIONS_GET_LIST,
  TIME_CONDITIONS_GET_LIST_SUCCESS,
  TIME_CONDITIONS_GET_LIST_ERROR,
  TIME_CONDITIONS_ADD_ITEM,
  TIME_CONDITIONS_ADD_ITEM_SUCCESS,
  TIME_CONDITIONS_ADD_ITEM_ERROR,
  TIME_CONDITIONS_EDIT_ITEM,
  TIME_CONDITIONS_EDIT_ITEM_SUCCESS,
  TIME_CONDITIONS_EDIT_ITEM_ERROR,
  TIMECONDITION_DESTINATIONS_LIST,
  TIMECONDITION_DESTINATIONS_LIST_SUCCESS,
  TIMECONDITION_DESTINATIONS_LIST_ERROR,
  TIMECONDITION_NON_DESTINATIONS_LIST,
  TIMECONDITION_NON_DESTINATIONS_LIST_SUCCESS,
  TIMECONDITION_NON_DESTINATIONS_LIST_ERROR,
} from '../actions';

const INIT_STATE = {
  timeConditions: [],
  timeZones: [
    'Europe/Andorra',
    'Asia/Dubai',
    'Asia/Kabul',
    'Europe/Tirane',
    'Asia/Yerevan',
    'Antarctica/Casey',
    'Antarctica/Davis',
    'Antarctica/DumontDUrville',
    'Antarctica/Mawson',
    'Antarctica/Palmer',
    'Antarctica/Rothera',
    'Antarctica/Syowa',
    'Antarctica/Troll',
    'Antarctica/Vostok',
    'America/Argentina/Buenos_Aires',
    'America/Argentina/Cordoba',
    'America/Argentina/Salta',
    'America/Argentina/Jujuy',
    'America/Argentina/Tucuman',
    'America/Argentina/Catamarca',
    'America/Argentina/La_Rioja',
    'America/Argentina/San_Juan',
    'America/Argentina/Mendoza',
    'America/Argentina/San_Luis',
    'America/Argentina/Rio_Gallegos',
    'America/Argentina/Ushuaia',
    'Pacific/Pago_Pago',
    'Europe/Vienna',
    'Australia/Lord_Howe',
    'Antarctica/Macquarie',
    'Australia/Hobart',
    'Australia/Currie',
    'Australia/Melbourne',
    'Australia/Sydney',
    'Australia/Broken_Hill',
    'Australia/Brisbane',
    'Australia/Lindeman',
    'Australia/Adelaide',
    'Australia/Darwin',
    'Australia/Perth',
    'Australia/Eucla',
    'Asia/Baku',
    'America/Barbados',
    'Asia/Dhaka',
    'Europe/Brussels',
    'Europe/Sofia',
    'Atlantic/Bermuda',
    'Asia/Brunei',
    'America/La_Paz',
    'America/Noronha',
    'America/Belem',
    'America/Fortaleza',
    'America/Recife',
    'America/Araguaina',
    'America/Maceio',
    'America/Bahia',
    'America/Sao_Paulo',
    'America/Campo_Grande',
    'America/Cuiaba',
    'America/Santarem',
    'America/Porto_Velho',
    'America/Boa_Vista',
    'America/Manaus',
    'America/Eirunepe',
    'America/Rio_Branco',
    'America/Nassau',
    'Asia/Thimphu',
    'Europe/Minsk',
    'America/Belize',
    'America/St_Johns',
    'America/Halifax',
    'America/Glace_Bay',
    'America/Moncton',
    'America/Goose_Bay',
    'America/Blanc-Sablon',
    'America/Toronto',
    'America/Nipigon',
    'America/Thunder_Bay',
    'America/Iqaluit',
    'America/Pangnirtung',
    'America/Atikokan',
    'America/Winnipeg',
    'America/Rainy_River',
    'America/Resolute',
    'America/Rankin_Inlet',
    'America/Regina',
    'America/Swift_Current',
    'America/Edmonton',
    'America/Cambridge_Bay',
    'America/Yellowknife',
    'America/Inuvik',
    'America/Creston',
    'America/Dawson_Creek',
    'America/Fort_Nelson',
    'America/Vancouver',
    'America/Whitehorse',
    'America/Dawson',
    'Indian/Cocos',
    'Europe/Zurich',
    'Africa/Abidjan',
    'Pacific/Rarotonga',
    'America/Santiago',
    'America/Punta_Arenas',
    'Pacific/Easter',
    'Asia/Shanghai',
    'Asia/Urumqi',
    'America/Bogota',
    'America/Costa_Rica',
    'America/Havana',
    'Atlantic/Cape_Verde',
    'America/Curacao',
    'Indian/Christmas',
    'Asia/Nicosia',
    'Asia/Famagusta',
    'Europe/Prague',
    'Europe/Berlin',
    'Europe/Copenhagen',
    'America/Santo_Domingo',
    'Africa/Algiers',
    'America/Guayaquil',
    'Pacific/Galapagos',
    'Europe/Tallinn',
    'Africa/Cairo',
    'Africa/El_Aaiun',
    'Europe/Madrid',
    'Africa/Ceuta',
    'Atlantic/Canary',
    'Europe/Helsinki',
    'Pacific/Fiji',
    'Atlantic/Stanley',
    'Pacific/Chuuk',
    'Pacific/Pohnpei',
    'Pacific/Kosrae',
    'Atlantic/Faroe',
    'Europe/Paris',
    'Europe/London',
    'Asia/Tbilisi',
    'America/Cayenne',
    'Africa/Accra',
    'Europe/Gibraltar',
    'America/Godthab',
    'America/Danmarkshavn',
    'America/Scoresbysund',
    'America/Thule',
    'Europe/Athens',
    'Atlantic/South_Georgia',
    'America/Guatemala',
    'Pacific/Guam',
    'Africa/Bissau',
    'America/Guyana',
    'Asia/Hong_Kong',
    'America/Tegucigalpa',
    'America/Port-au-Prince',
    'Europe/Budapest',
    'Asia/Jakarta',
    'Asia/Pontianak',
    'Asia/Makassar',
    'Asia/Jayapura',
    'Europe/Dublin',
    'Asia/Jerusalem',
    'Asia/Kolkata',
    'Indian/Chagos',
    'Asia/Baghdad',
    'Asia/Tehran',
    'Atlantic/Reykjavik',
    'Europe/Rome',
    'America/Jamaica',
    'Asia/Amman',
    'Asia/Tokyo',
    'Africa/Nairobi',
    'Asia/Bishkek',
    'Pacific/Tarawa',
    'Pacific/Enderbury',
    'Pacific/Kiritimati',
    'Asia/Pyongyang',
    'Asia/Seoul',
    'Asia/Almaty',
    'Asia/Qyzylorda',
    'Asia/Qostanay',
    'Asia/Aqtobe',
    'Asia/Aqtau',
    'Asia/Atyrau',
    'Asia/Oral',
    'Asia/Beirut',
    'Asia/Colombo',
    'Africa/Monrovia',
    'Europe/Vilnius',
    'Europe/Luxembourg',
    'Europe/Riga',
    'Africa/Tripoli',
    'Africa/Casablanca',
    'Europe/Monaco',
    'Europe/Chisinau',
    'Pacific/Majuro',
    'Pacific/Kwajalein',
    'Asia/Yangon',
    'Asia/Ulaanbaatar',
    'Asia/Hovd',
    'Asia/Choibalsan',
    'Asia/Macau',
    'America/Martinique',
    'Europe/Malta',
    'Indian/Mauritius',
    'Indian/Maldives',
    'America/Mexico_City',
    'America/Cancun',
    'America/Merida',
    'America/Monterrey',
    'America/Matamoros',
    'America/Mazatlan',
    'America/Chihuahua',
    'America/Ojinaga',
    'America/Hermosillo',
    'America/Tijuana',
    'America/Bahia_Banderas',
    'Asia/Kuala_Lumpur',
    'Asia/Kuching',
    'Africa/Maputo',
    'Africa/Windhoek',
    'Pacific/Noumea',
    'Pacific/Norfolk',
    'Africa/Lagos',
    'America/Managua',
    'Europe/Amsterdam',
    'Europe/Oslo',
    'Asia/Kathmandu',
    'Pacific/Nauru',
    'Pacific/Niue',
    'Pacific/Auckland',
    'Pacific/Chatham',
    'America/Panama',
    'America/Lima',
    'Pacific/Tahiti',
    'Pacific/Marquesas',
    'Pacific/Gambier',
    'Pacific/Port_Moresby',
    'Pacific/Bougainville',
    'Asia/Manila',
    'Asia/Karachi',
    'Europe/Warsaw',
    'America/Miquelon',
    'Pacific/Pitcairn',
    'America/Puerto_Rico',
    'Asia/Gaza',
    'Asia/Hebron',
    'Europe/Lisbon',
    'Atlantic/Madeira',
    'Atlantic/Azores',
    'Pacific/Palau',
    'America/Asuncion',
    'Asia/Qatar',
    'Indian/Reunion',
    'Europe/Bucharest',
    'Europe/Belgrade',
    'Europe/Kaliningrad',
    'Europe/Moscow',
    'Europe/Simferopol',
    'Europe/Kirov',
    'Europe/Astrakhan',
    'Europe/Volgograd',
    'Europe/Saratov',
    'Europe/Ulyanovsk',
    'Europe/Samara',
    'Asia/Yekaterinburg',
    'Asia/Omsk',
    'Asia/Novosibirsk',
    'Asia/Barnaul',
    'Asia/Tomsk',
    'Asia/Novokuznetsk',
    'Asia/Krasnoyarsk',
    'Asia/Irkutsk',
    'Asia/Chita',
    'Asia/Yakutsk',
    'Asia/Khandyga',
    'Asia/Vladivostok',
    'Asia/Ust-Nera',
    'Asia/Magadan',
    'Asia/Sakhalin',
    'Asia/Srednekolymsk',
    'Asia/Kamchatka',
    'Asia/Anadyr',
    'Asia/Riyadh',
    'Pacific/Guadalcanal',
    'Indian/Mahe',
    'Africa/Khartoum',
    'Europe/Stockholm',
    'Asia/Singapore',
    'America/Paramaribo',
    'Africa/Juba',
    'Africa/Sao_Tome',
    'America/El_Salvador',
    'Asia/Damascus',
    'America/Grand_Turk',
    'Africa/Ndjamena',
    'Indian/Kerguelen',
    'Asia/Bangkok',
    'Asia/Dushanbe',
    'Pacific/Fakaofo',
    'Asia/Dili',
    'Asia/Ashgabat',
    'Africa/Tunis',
    'Pacific/Tongatapu',
    'Europe/Istanbul',
    'America/Port_of_Spain',
    'Pacific/Funafuti',
    'Asia/Taipei',
    'Europe/Kiev',
    'Europe/Uzhgorod',
    'Europe/Zaporozhye',
    'Pacific/Wake',
    'America/New_York',
    'America/Detroit',
    'America/Kentucky/Louisville',
    'America/Kentucky/Monticello',
    'America/Indiana/Indianapolis',
    'America/Indiana/Vincennes',
    'America/Indiana/Winamac',
    'America/Indiana/Marengo',
    'America/Indiana/Petersburg',
    'America/Indiana/Vevay',
    'America/Chicago',
    'America/Indiana/Tell_City',
    'America/Indiana/Knox',
    'America/Menominee',
    'America/North_Dakota/Center',
    'America/North_Dakota/New_Salem',
    'America/North_Dakota/Beulah',
    'America/Denver',
    'America/Boise',
    'America/Phoenix',
    'America/Los_Angeles',
    'America/Anchorage',
    'America/Juneau',
    'America/Sitka',
    'America/Metlakatla',
    'America/Yakutat',
    'America/Nome',
    'America/Adak',
    'Pacific/Honolulu',
    'America/Montevideo',
    'Asia/Samarkand',
    'Asia/Tashkent',
    'America/Caracas',
    'Asia/Ho_Chi_Minh',
    'Pacific/Efate',
    'Pacific/Wallis',
    'Pacific/Apia',
    'Africa/Johannesburg',
  ],
  // prettier-ignore
  times: {
    '0000': '12:00 am',
    '0015': '12:15 am',
    '0030': '12:30 am',
    '0045': '12:45 am',
    '0100': '01:00 am',
    '0115': '01:15 am',
    '0130': '01:30 am',
    '0145': '01:45 am',
    '0200': '02:00 am',
    '0215': '02:15 am',
    '0230': '02:30 am',
    '0245': '02:45 am',
    '0300': '03:00 am',
    '0315': '03:15 am',
    '0330': '03:30 am',
    '0345': '03:45 am',
    '0400': '04:00 am',
    '0415': '04:15 am',
    '0430': '04:30 am',
    '0445': '04:45 am',
    '0500': '05:00 am',
    '0515': '05:15 am',
    '0530': '05:30 am',
    '0545': '05:45 am',
    '0600': '06:00 am',
    '0615': '06:15 am',
    '0630': '06:30 am',
    '0645': '06:45 am',
    '0700': '07:00 am',
    '0715': '07:15 am',
    '0730': '07:30 am',
    '0745': '07:45 am',
    '0800': '08:00 am',
    '0815': '08:15 am',
    '0830': '08:30 am',
    '0845': '08:45 am',
    '0900': '09:00 am',
    '0915': '09:15 am',
    '0930': '09:30 am',
    '0945': '09:45 am',
    '1000': '10:00 am',
    '1015': '10:15 am',
    '1030': '10:30 am',
    '1045': '10:45 am',
    '1100': '11:00 am',
    '1115': '11:15 am',
    '1130': '11:30 am',
    '1145': '11:45 am',
    '1200': '12:00 pm',
    '1215': '12:15 pm',
    '1230': '12:30 pm',
    '1245': '12:45 pm',
    '1300': '01:00 pm',
    '1315': '01:15 pm',
    '1330': '01:30 pm',
    '1345': '01:45 pm',
    '1400': '02:00 pm',
    '1415': '02:15 pm',
    '1430': '02:30 pm',
    '1445': '02:45 pm',
    '1500': '03:00 pm',
    '1515': '03:15 pm',
    '1530': '03:30 pm',
    '1545': '03:45 pm',
    '1600': '04:00 pm',
    '1615': '04:15 pm',
    '1630': '04:30 pm',
    '1645': '04:45 pm',
    '1700': '05:00 pm',
    '1715': '05:15 pm',
    '1730': '05:30 pm',
    '1745': '05:45 pm',
    '1800': '06:00 pm',
    '1815': '06:15 pm',
    '1830': '06:30 pm',
    '1845': '06:45 pm',
    '1900': '07:00 pm',
    '1915': '07:15 pm',
    '1930': '07:30 pm',
    '1945': '07:45 pm',
    '2000': '08:00 pm',
    '2015': '08:15 pm',
    '2030': '08:30 pm',
    '2045': '08:45 pm',
    '2100': '09:00 pm',
    '2115': '09:15 pm',
    '2130': '09:30 pm',
    '2145': '09:45 pm',
    '2200': '10:00 pm',
    '2215': '10:15 pm',
    '2230': '10:30 pm',
    '2245': '10:45 pm',
    '2300': '11:00 pm',
    '2315': '11:15 pm',
    '2330': '11:30 pm',
    '2345': '11:45 pm',
  },
  days: {
    1: 'MON',
    2: 'TUE',
    3: 'WED',
    4: 'THU',
    5: 'FRI',
    6: 'SAT',
    0: 'SUN',
  },
  timeConditionsDestinationNames: { ...defaultDestinationNames },
  timeConditionsDestinations: [],
  timeConditionsNonDestinationNames: {
    ...defaultDestinationNames,
    hangup: 'Hangup',
  },
  timeConditionsNonDestinations: [],
  title: '',
  error: '',
  loading: false,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case TIME_CONDITIONS_GET_LIST:
      return { ...state, loading: false };

    case TIME_CONDITIONS_GET_LIST_SUCCESS:
      return {
        ...state,
        loading: true,
        timeConditions: action.payload,
      };

    case TIME_CONDITIONS_GET_LIST_ERROR:
      return { ...state, loading: true, error: action.payload };

    case TIMECONDITION_DESTINATIONS_LIST:
      return { ...state, loading: false };

    case TIMECONDITION_DESTINATIONS_LIST_SUCCESS:
      return {
        ...state,
        loading: true,
        timeConditionsDestinations: action.payload,
      };

    case TIMECONDITION_DESTINATIONS_LIST_ERROR:
      return { ...state, loading: true, error: action.payload };

    case TIMECONDITION_NON_DESTINATIONS_LIST:
      return { ...state, loading: false };

    case TIMECONDITION_NON_DESTINATIONS_LIST_SUCCESS:
      return {
        ...state,
        loading: true,
        timeConditionsNonDestinations: action.payload,
      };

    case TIMECONDITION_NON_DESTINATIONS_LIST_ERROR:
      return { ...state, loading: true, error: action.payload };

    case TIME_CONDITIONS_ADD_ITEM:
      return { ...state, loading: false };

    case TIME_CONDITIONS_ADD_ITEM_SUCCESS:
      if (action.payload === null) {
        return {
          ...state,
          loading: true,
        };
      }
      window.location.reload();
      return {
        ...state,
        loading: true,
        timeConditions: action.payload,
      };

    case TIME_CONDITIONS_ADD_ITEM_ERROR:
      return { ...state, loading: true, error: action.payload };

    case TIME_CONDITIONS_EDIT_ITEM:
      return { ...state, loading: false };

    case TIME_CONDITIONS_EDIT_ITEM_SUCCESS:
      if (action.payload === null) {
        return {
          ...state,
          loading: true,
        };
      }
      window.location.reload();
      return {
        ...state,
        loading: true,
        timeConditions: action.payload,
      };

    case TIME_CONDITIONS_EDIT_ITEM_ERROR:
      return { ...state, loading: true, error: action.payload };

    default:
      return { ...state };
  }
};
