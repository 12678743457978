import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import axios from 'axios';

import {
  MISS_CALL_BOX_GET_LIST,
  MISS_CALL_BOX_ADD_ITEM,
  MISS_CALL_BOX_EDIT_ITEM,
  MISS_CALL_BOX_GET_EMPLOYEE_LIST,
  MISS_CALL_BOX_GET_NUMBER_LIST,
} from '../actions';

import {
  getMisscallBoxListSuccess,
  getMisscallBoxListError,
  addMisscallBoxItemSuccess,
  addMisscallBoxItemError,
  editMisscallBoxItemSuccess,
  editMisscallBoxItemError,
  getMisscallBoxEmployeeListsSuccess,
  getMisscallBoxEmployeeListsError,
  getMisscallBoxNumberListsSuccess,
  getMisscallBoxNumberListsError,
} from './actions';

const getMisscallBoxEmployeeListRequest = async () => {
  // eslint-disable-next-line no-return-await
  return await new Promise((success) => {
    axios
      .get('/dashboard/getBitrixEmployees')
      .then((response) => success(response?.data?.employees))
      .catch(() => success([]));
  })
    .then((response) => response)
    .catch((error) => error);
};

function* getMisscallBoxEmployeeListItems() {
  try {
    const response = yield call(getMisscallBoxEmployeeListRequest);
    yield put(getMisscallBoxEmployeeListsSuccess(response));
  } catch (error) {
    yield put(getMisscallBoxEmployeeListsError(error));
  }
}

const getMisscallBoxNumberListRequest = async () => {
  // eslint-disable-next-line no-return-await
  return await new Promise((success) => {
    axios
      .get('/dashboard/missedCallBoxNumbers')
      .then((response) => success(response?.data?.payload))
      .catch(() => success([]));
  })
    .then((response) => response)
    .catch((error) => error);
};

function* getMisscallBoxNumberListItems() {
  try {
    const response = yield call(getMisscallBoxNumberListRequest);
    yield put(getMisscallBoxNumberListsSuccess(response));
  } catch (error) {
    yield put(getMisscallBoxNumberListsError(error));
  }
}

const getMisscallBoxListRequest = async () => {
  // eslint-disable-next-line no-return-await
  return await new Promise((success) => {
    axios
      .get('/flow/missedCallBoxs/')
      .then((response) => success(Object.values(response?.data?.payload)))
      .catch(() => success([]));
  })
    .then((response) => response)
    .catch((error) => error);
};

function* getMisscallBoxListItems() {
  try {
    const response = yield call(getMisscallBoxListRequest);
    yield put(getMisscallBoxListSuccess(response));
  } catch (error) {
    yield put(getMisscallBoxListError(error));
  }
}

const addMisscallBoxItemRequest = async (item) => {
  // eslint-disable-next-line no-return-await
  return await new Promise((success) => {
    axios
      .post('/flow/missedCallBox', { ...item })
      .then((response) => success(response.data.misscallBox))
      .catch(() => success(null));
  })
    .then((response) => response)
    .catch((error) => error);
};

function* addMisscallBoxItem({ payload }) {
  try {
    const response = yield call(addMisscallBoxItemRequest, payload);
    yield put(addMisscallBoxItemSuccess(response));
  } catch (error) {
    yield put(addMisscallBoxItemError(error));
  }
}

const editMisscallBoxItemRequest = async (item) => {
  // eslint-disable-next-line no-return-await
  return await new Promise((success) => {
    axios
      .put(`/flow/missedCallBox/${item.misscallBoxId}`, { ...item })
      .then((response) => success(response.data.misscallBox))
      .catch(() => success(null));
  })
    .then((response) => response)
    .catch((error) => error);
};

function* editMisscallBoxItem({ payload }) {
  try {
    const response = yield call(editMisscallBoxItemRequest, payload);
    yield put(editMisscallBoxItemSuccess(response));
  } catch (error) {
    yield put(editMisscallBoxItemError(error));
  }
}

export function* watchGetList() {
  yield takeEvery(MISS_CALL_BOX_GET_LIST, getMisscallBoxListItems);
}

export function* watchGetMisscallBoxEmployeeList() {
  yield takeEvery(
    MISS_CALL_BOX_GET_EMPLOYEE_LIST,
    getMisscallBoxEmployeeListItems
  );
}

export function* watchGetMisscallBoxNumberList() {
  yield takeEvery(MISS_CALL_BOX_GET_NUMBER_LIST, getMisscallBoxNumberListItems);
}

export function* wathcAddItem() {
  yield takeEvery(MISS_CALL_BOX_ADD_ITEM, addMisscallBoxItem);
}

export function* wathcEditItem() {
  yield takeEvery(MISS_CALL_BOX_EDIT_ITEM, editMisscallBoxItem);
}

export default function* rootSaga() {
  yield all([
    fork(watchGetList),
    fork(wathcAddItem),
    fork(wathcEditItem),
    fork(watchGetMisscallBoxEmployeeList),
    fork(watchGetMisscallBoxNumberList),
  ]);
}
