// eslint-disable-next-line import/no-cycle
import {
  ROBO_GET_LIST,
  ROBO_GET_LIST_SUCCESS,
  ROBO_GET_LIST_ERROR,
  ROBO_ADD_ITEM,
  ROBO_ADD_ITEM_SUCCESS,
  ROBO_ADD_ITEM_ERROR,
  ROBO_GET_EMPLOYEE_LIST,
  ROBO_GET_EMPLOYEE_LIST_SUCCESS,
  ROBO_GET_EMPLOYEE_LIST_ERROR,
  ROBO_GET_NUMBERS_LIST,
  ROBO_GET_NUMBERS_LIST_SUCCESS,
  ROBO_GET_NUMBERS_LIST_ERROR,
} from '../actions';

export const getRoboList = () => ({
  type: ROBO_GET_LIST,
});

export const getRoboListSuccess = (items) => ({
  type: ROBO_GET_LIST_SUCCESS,
  payload: items,
});

export const getRoboListError = (error) => ({
  type: ROBO_GET_LIST_ERROR,
  payload: error,
});

export const getRoboEmployeeLists = (destinationName) => ({
  type: ROBO_GET_EMPLOYEE_LIST,
  payload: destinationName,
});

export const getRoboEmployeeListsSuccess = (items) => ({
  type: ROBO_GET_EMPLOYEE_LIST_SUCCESS,
  payload: items,
});

export const getRoboEmployeeListsError = (error) => ({
  type: ROBO_GET_EMPLOYEE_LIST_ERROR,
  payload: error,
});

export const getRoboNumbersLists = (destinationName) => ({
  type: ROBO_GET_NUMBERS_LIST,
  payload: destinationName,
});

export const getRoboNumbersListsSuccess = (items) => ({
  type: ROBO_GET_NUMBERS_LIST_SUCCESS,
  payload: items,
});

export const getRoboNumbersListsError = (error) => ({
  type: ROBO_GET_NUMBERS_LIST_ERROR,
  payload: error,
});

export const addRoboItem = (item) => ({
  type: ROBO_ADD_ITEM,
  payload: item,
});

export const addRoboItemSuccess = (items) => ({
  type: ROBO_ADD_ITEM_SUCCESS,
  payload: items,
});

export const addRoboItemError = (error) => ({
  type: ROBO_ADD_ITEM_ERROR,
  payload: error,
});
