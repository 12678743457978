/* eslint-disable no-plusplus */
import axios from 'axios';
import { NotificationManager } from '../components/common/react-notifications';

import { ThemeColors } from './ThemeColors';
import {
  defaultDirection,
  defaultLocale,
  defaultColor,
  localeOptions,
  themeColorStorageKey,
  themeRadiusStorageKey,
  baseUrl,
} from '../constants/defaultValues';

export const mapOrder = (array, order, key) => {
  // eslint-disable-next-line func-names
  array.sort(function (a, b) {
    const A = a[key];
    const B = b[key];
    if (order.indexOf(`${A}`) > order.indexOf(`${B}`)) {
      return 1;
    }
    return -1;
  });
  return array;
};

export const getDateWithFormat = () => {
  const today = new Date();
  let dd = today.getDate();
  let mm = today.getMonth() + 1; // January is 0!

  const yyyy = today.getFullYear();
  if (dd < 10) {
    dd = `0${dd}`;
  }
  if (mm < 10) {
    mm = `0${mm}`;
  }
  return `${dd}.${mm}.${yyyy}`;
};

export const getCurrentTime = () => {
  const now = new Date();
  return `${now.getHours()}:${now.getMinutes()}`;
};

export const getDirection = () => {
  let direction = defaultDirection;

  try {
    if (localStorage.getItem('direction')) {
      const localValue = localStorage.getItem('direction');
      if (localValue === 'rtl' || localValue === 'ltr') {
        direction = localValue;
      }
    }
  } catch (error) {
    console.log('>>>>: src/helpers/Utils.js : getDirection -> error', error);
    direction = defaultDirection;
  }
  return {
    direction,
    isRtl: direction === 'rtl',
  };
};
export const setDirection = (localValue) => {
  let direction = 'ltr';
  if (localValue === 'rtl' || localValue === 'ltr') {
    direction = localValue;
  }
  try {
    localStorage.setItem('direction', direction);
  } catch (error) {
    console.log('>>>>: src/helpers/Utils.js : setDirection -> error', error);
  }
};

export const getCurrentColor = () => {
  let currentColor = defaultColor;
  try {
    if (localStorage.getItem(themeColorStorageKey)) {
      currentColor = localStorage.getItem(themeColorStorageKey);
    }
  } catch (error) {
    console.log('>>>>: src/helpers/Utils.js : getCurrentColor -> error', error);
    currentColor = defaultColor;
  }
  return currentColor;
};

export const setCurrentColor = (color) => {
  try {
    localStorage.setItem(themeColorStorageKey, color);
  } catch (error) {
    console.log('>>>>: src/helpers/Utils.js : setCurrentColor -> error', error);
  }
};

export const getCurrentRadius = () => {
  let currentRadius = 'rounded';
  try {
    if (localStorage.getItem(themeRadiusStorageKey)) {
      currentRadius = localStorage.getItem(themeRadiusStorageKey);
    }
  } catch (error) {
    console.log(
      '>>>>: src/helpers/Utils.js : getCurrentRadius -> error',
      error
    );
    currentRadius = 'rounded';
  }
  return currentRadius;
};
export const setCurrentRadius = (radius) => {
  try {
    localStorage.setItem(themeRadiusStorageKey, radius);
  } catch (error) {
    console.log(
      '>>>>: src/helpers/Utils.js : setCurrentRadius -> error',
      error
    );
  }
};

export const getCurrentLanguage = () => {
  let language = defaultLocale;
  try {
    language =
      localStorage.getItem('currentLanguage') &&
      localeOptions.filter(
        (x) => x.id === localStorage.getItem('currentLanguage')
      ).length > 0
        ? localStorage.getItem('currentLanguage')
        : defaultLocale;
  } catch (error) {
    console.log(
      '>>>>: src/helpers/Utils.js : getCurrentLanguage -> error',
      error
    );
    language = defaultLocale;
  }
  return language;
};
export const setCurrentLanguage = (locale) => {
  try {
    localStorage.setItem('currentLanguage', locale);
  } catch (error) {
    console.log(
      '>>>>: src/helpers/Utils.js : setCurrentLanguage -> error',
      error
    );
  }
};

export const getCurrentUser = () => {
  let user = null;
  try {
    user =
      localStorage.getItem('ustelephony24User') != null
        ? JSON.parse(localStorage.getItem('ustelephony24User'))
        : null;
  } catch (error) {
    console.log('>>>>: src/helpers/Utils.js  : getCurrentUser -> error', error);
    user = null;
  }
  return user;
};

export const setCurrentUser = (user) => {
  try {
    if (user) {
      localStorage.setItem('ustelephony24User', JSON.stringify(user));
    } else {
      localStorage.removeItem('ustelephony24User');
    }
  } catch (error) {
    console.log('>>>>: src/helpers/Utils.js : setCurrentUser -> error', error);
  }
};

export const generateUrl = (destinationName) => {
  let url = '';
  switch (destinationName) {
    case 'voicemail':
      url = '/flow/voicemails/';
      break;

    case 'ivr':
      url = '/flow/ivrs/';
      break;

    case 'timeCondition':
      url = '/flow/timeConditions/';
      break;

    case 'queues':
      url = '/flow/queues/';
      break;

    case 'callForwarding':
      url = '/flow/callForwardings/';
      break;

    case 'employee':
      url = '/flow/employees/';
      break;

    default:
      break;
  }
  return url;
};

export const generateSMSUrl = (destinationName) => {
  let url = '';
  switch (destinationName) {
    case 'smsQueues':
      url = '/flow/smsQueues/';
      break;

    case 'employee':
      url = '/dashboard/getAllEmployees';
      break;

    default:
      break;
  }
  return url;
};

export const convertDayToName = (day) => {
  switch (day) {
    case '1':
      return 'MON';
    case '2':
      return 'TUE';
    case '3':
      return 'WED';
    case '4':
      return 'THU';
    case '5':
      return 'FRI';
    case '6':
      return 'SAT';
    case '0':
      return 'SUN';

    default:
      return '';
  }
};

export const defaultDestinationNames = {
  employee: 'Employee',
  voicemail: 'Voicemail',
  queues: 'Queue',
  ivr: 'IVR',
  callForwarding: 'Call Forwarding',
  timeCondition: 'Business Hours',
};

export const defaultDestinationNamesForDashboard = {
  employee: 'Employee',
  voicemail: 'Voicemail',
  queues: 'Queue',
  ivr: 'IVR',
  callForwarding: 'Call Forwarding',
  timeCondition: 'Business Hours',
  hangup: 'Hangup',
};

export const defaultSMSDestinationNamesForDashboard = {
  employee: 'Employee',
  smsQueues: 'SMS Queues',
  noRoute: `Don't Notify`,
};

export const defaultDestinationNamesForEmployee = {
  employee: 'Employee',
  voicemail: 'Voicemail',
  callForwarding: 'Call Forwarding',
};

export const getDestinationLabel = (destinationName) => {
  let label = '';
  switch (destinationName) {
    case 'voicemail':
      label = 'Voicemail';
      break;

    case 'ivr':
      label = 'IVR';
      break;

    case 'timeCondition':
      label = 'Business Hours';
      break;

    case 'queues':
      label = 'Queues';
      break;

    case 'callForwarding':
      label = 'Call Forwarding';
      break;

    case 'employee':
      label = 'Employee';
      break;

    case 'noRoute':
      label = `Don't Notify`;
      break;

    case 'default':
      label = 'Default';
      break;

    default:
      break;
  }
  return label;
};

export const convertDateToDaysRemaining = (date) => {
  const currentDate = new Date();
  const startDate = new Date(date);
  startDate.setDate(startDate.getDate() + 30);
  const timeDifference = startDate.getTime() - currentDate.getTime();
  return Math.floor(timeDifference / (1000 * 60 * 60 * 24));
};

export const createLineChartData = (allCalls, allDates) => ({
  labels: allDates,
  datasets: [
    {
      label: '',
      data: allCalls,
      borderColor: ThemeColors().themeColor1,
      pointBackgroundColor: ThemeColors().foregroundColor,
      pointBorderColor: ThemeColors().themeColor1,
      pointHoverBackgroundColor: ThemeColors().themeColor1,
      pointHoverBorderColor: ThemeColors().foregroundColor,
      pointRadius: 4,
      pointBorderWidth: 2,
      pointHoverRadius: 5,
      fill: true,
      borderWidth: 2,
      backgroundColor: ThemeColors().themeColor1_10,
    },
  ],
});

export const createAreaChartOptions = (min, max) => {
  const areaChartOptions = {
    legend: {
      display: false,
    },
    responsive: true,
    maintainAspectRatio: false,
    tooltips: {
      backgroundColor: ThemeColors().foregroundColor,
      titleFontColor: ThemeColors().primaryColor,
      borderColor: ThemeColors().separatorColor,
      borderWidth: 0.5,
      bodyFontColor: ThemeColors().primaryColor,
      bodySpacing: 10,
      xPadding: 15,
      yPadding: 15,
      cornerRadius: 0.15,
    },
    scales: {
      yAxes: [
        {
          gridLines: {
            display: true,
            lineWidth: 1,
            color: 'rgba(0,0,0,0.1)',
            drawBorder: false,
          },
          ticks: {
            beginAtZero: true,
            stepSize: 5,
            min,
            max,
            padding: 20,
          },
        },
      ],
      xAxes: [
        {
          gridLines: {
            display: false,
          },
        },
      ],
    },
  };
  return areaChartOptions;
};

export const showErrorNotification = (message) => {
  NotificationManager.error(null, message, 6000, null, null, null);
};

export const showSuccessNotification = (message) => {
  NotificationManager.success(null, message, 4000, null, null, null);
};

export const initAxios = () => {
  const token =
    localStorage.getItem('ustelephony24User') &&
    JSON.parse(localStorage.getItem('ustelephony24User')).token;
  axios.defaults.baseURL = baseUrl;
  if (token) {
    axios.interceptors.request.use((config) => {
      // eslint-disable-next-line no-param-reassign
      config.headers.Authorization = `Bearer ${token}`;
      return config;
    });
    axios.interceptors.response.use(
      (response) => {
        return response;
      },
      (error) => {
        const errorResponse = error.response;
        const errorMessage =
          errorResponse?.data?.message ||
          errorResponse?.data?.deleteMessage ||
          error?.message ||
          'Something went wrong!';
        const errorStatus = errorResponse?.status;
        if (errorStatus === 403 && errorMessage === 'jwt expired') {
          localStorage.removeItem('ustelephony24User');
          localStorage.setItem('jwtError', true);
          window.location.reload();
        } else if (errorMessage) {
          showErrorNotification(errorMessage);
        }
        throw error;
      }
    );
  }
};

export const capitalizeColName = (colName) => {
  let newColName = colName.charAt(0).toUpperCase() + colName.slice(1);
  const callKeys = colName.split('_');
  if (callKeys.length === 2) {
    newColName = [
      callKeys[0].charAt(0).toUpperCase() + callKeys[0].slice(1),
      callKeys[1].charAt(0).toUpperCase() + callKeys[1].slice(1),
    ].join(' ');
  }
  return newColName;
};

export const mapQueueStrategy = (strategy) => {
  let strategyTitle = '';
  switch (strategy) {
    case 'roundRobin':
      strategyTitle = 'Round Robin';
      break;
    case 'sequential':
      strategyTitle = 'Sequential';
      break;
    case 'simultaneous':
      strategyTitle = 'Simultaneous';
      break;
    default:
      break;
  }
  return strategyTitle;
};

export const capitalizeString = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const employeeRoles = [
  {
    value: 'supervisor',
    key: 'supervisor',
    label: 'Supervisor',
  },
  {
    value: 'agent',
    key: 'agent',
    label: 'Agent',
  },
];

export const getNumber = async (country, numberType, numberPrefix) => {
  try {
    if (!country || !numberType) {
      showErrorNotification('Please select country and Number Type');
      return;
    }
    const numberResponse = await axios.get(
      `${process.env.REACT_APP_SERVER_URI}/dashboard/getPhoneNumber/${country}?type=${numberType}&prefix=${numberPrefix}`
    );
    const numbersData = numberResponse?.data?.numbers || [];
    const filteredNumbers = numbersData.map((number) => ({
      value: number.id,
      label: number.number,
      key: number.id,
    }));
    // eslint-disable-next-line consistent-return
    return filteredNumbers;
  } catch (err) {
    showErrorNotification(
      'Error Searching Number, Please try again in some time'
    );
  }
};

export const uploadFile = async (fileRecording) => {
  const formData = new FormData();
  formData.append('mp3', fileRecording);
  const data = await axios.post('/files/upload', formData);
  return data?.data?.location;
};

export const validateRegisterDetail = (
  firstName,
  lastName,
  email,
  password,
  isTermsSelected
) => {
  let isValid = true;
  const passwordRegEx =
    /(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*()+=-?;,./{}|":<>[\]\\' ~_]).{8,20}/;
  const emailRegEx =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  if (!firstName || firstName.length < 3 || firstName.length > 50) {
    showErrorNotification(
      'First name should be between 3 to 50 characters long.'
    );
    isValid = false;
  } else if (!lastName || lastName.length < 3 || lastName.length > 50) {
    showErrorNotification(
      'Last name should be between 3 to 50 characters long.'
    );
    isValid = false;
  } else if (!emailRegEx.test(email)) {
    showErrorNotification('Email is invalid.');
    isValid = false;
  } else if (
    !passwordRegEx.test(password) ||
    password.trim().length < 7 ||
    password.trim().length > 15
  ) {
    showErrorNotification(
      'Password should be between 8 to 20 characters long and must contain at least 1 Uppercase, 1 Lowercase, 1 Digit and 1 Special character.'
    );
    isValid = false;
  } else if (!isTermsSelected) {
    showErrorNotification('Please select Terms and Conditions.');
    isValid = false;
  }
  return isValid;
};

export const validateCompanyDetails = (
  companyName,
  website,
  companyType,
  industry,
  ein,
  einCountry
) => {
  let isValid = true;
  const websiteRegEx = new RegExp(
    '^(https?:\\/\\/)?' + // protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
      '(\\#[-a-z\\d_]*)?$',
    'i'
  ); // fragment locator

  if (!companyName) {
    showErrorNotification('Company name cannot be empty.');
    isValid = false;
  } else if (!website || !websiteRegEx.test(website)) {
    showErrorNotification('Website not valid.');
    isValid = false;
  } else if (!companyType) {
    showErrorNotification('Please select a company type.');
    isValid = false;
  } else if (!industry) {
    showErrorNotification('Please select an industry type.');
    isValid = false;
  } else if (!ein) {
    showErrorNotification('Please fill Business Tax Number.');
    isValid = false;
  } else if (!einCountry) {
    showErrorNotification('Please select Tax Issuing country.');
    isValid = false;
  }

  return isValid;
};

export const validateCompanyAddress = (street, city, stateCode, postalCode) => {
  let isValid = true;

  if (!street) {
    showErrorNotification('Please fill street.');
    isValid = false;
  } else if (!city) {
    showErrorNotification('Please fill city.');
    isValid = false;
  } else if (!stateCode) {
    showErrorNotification('Please select a state.');
    isValid = false;
  } else if (!postalCode) {
    showErrorNotification('Please fill postal code.');
    isValid = false;
  }

  return isValid;
};

export const validateUserDetails = (
  email,
  firstName,
  lastName,
  position,
  phoneNumber,
  title
) => {
  let isValid = true;
  const emailRegEx =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  if (!title) {
    showErrorNotification('Please select a title.');
    isValid = false;
  } else if (!firstName || firstName.length < 3 || firstName.length > 50) {
    showErrorNotification(
      'First name should be between 3 to 50 characters long.'
    );
    isValid = false;
  } else if (!lastName || lastName.length < 3 || lastName.length > 50) {
    showErrorNotification(
      'Last name should be between 3 to 50 characters long.'
    );
    isValid = false;
  } else if (!position) {
    showErrorNotification('Please fill position.');
    isValid = false;
  } else if (!emailRegEx.test(email)) {
    showErrorNotification('Email is invalid.');
    isValid = false;
  } else if (!phoneNumber) {
    showErrorNotification('Please fill your phone number.');
    isValid = false;
  }
  return isValid;
};

export const mapRoleNames = (role) => {
  switch (role) {
    case 'admin':
      return 'Admin';

    case 'supervisor':
      return 'Supervisor';

    default:
      return 'Agent';
  }
};

export const mapRoleClassNames = (role) => {
  switch (role) {
    case 'admin':
      return 'primary';

    case 'supervisor':
      return 'success';

    case 'agent':
      return 'secondary';

    default:
      return 'secondary';
  }
};

const validateCardNo = (number) => {
  const cardNumber = number.split(' ').join('');
  const americanExpressCardFormat = /^(?:3[47][0-9]{13})$/; // Amercican Express credit card
  const visaCardFormat = /^(?:4[0-9]{12}(?:[0-9]{3})?)$/; // Visa credit card
  const masterCardFormat = /^(?:5[1-5][0-9]{14})$/; // Mastercard
  const discoverCardFormat = /^(?:6(?:011|5[0-9][0-9])[0-9]{12})$/; // Discover card
  const dinnersClubCardFormat = /^(?:3(?:0[0-5]|[68][0-9])[0-9]{11})$/; // Dinners Club card
  const jcbCardFormat = /^(?:(?:2131|1800|35\d{3})\d{11})$/; // JCB card
  if (
    americanExpressCardFormat.test(cardNumber) ||
    visaCardFormat.test(cardNumber) ||
    masterCardFormat.test(cardNumber) ||
    discoverCardFormat.test(cardNumber) ||
    dinnersClubCardFormat.test(cardNumber) ||
    jcbCardFormat.test(cardNumber)
  ) {
    return true;
  }
  return false;
};

export const validateCardDetails = (
  firstName,
  lastName,
  number,
  expiryMonth,
  expiryYear,
  cvv,
  months,
  years
) => {
  let valid = true;
  if (
    !firstName ||
    !lastName ||
    !number ||
    !expiryMonth ||
    !expiryYear ||
    !cvv
  ) {
    showErrorNotification('Please fill all the details');
    valid = false;
  } else if (firstName.length < 3 || firstName.length > 15) {
    showErrorNotification(
      'First Name should be betweem 3 to 15 characters long.'
    );
    valid = false;
  } else if (lastName.length < 3 || lastName.length > 15) {
    showErrorNotification(
      'First Name should be betweem 3 to 15 characters long.'
    );
    valid = false;
  } else if (cvv.length < 3 || cvv.length > 4) {
    showErrorNotification('Please enter a valid CVV number.');
    valid = false;
  } else if (
    +expiryMonth > +months[months.length - 1] ||
    +expiryMonth < +months[0] ||
    +expiryYear > +years[years.length - 1] ||
    +expiryYear < +years[0]
  ) {
    showErrorNotification('Please select a valid expiration month and year.');
    valid = false;
  } else if (
    +expiryMonth <= new Date().getMonth() &&
    +expiryYear <= new Date().getFullYear()
  ) {
    showErrorNotification(
      'Please select a future date for expiration month and year.'
    );
    valid = false;
  } else if (!validateCardNo(number)) {
    showErrorNotification('Please add a valid card number.');
    valid = false;
  }

  return valid;
};

export const getSelectedPlanData = (selectedPlanName, pricesData) => {
  return pricesData.filter((priceData) => priceData.id === selectedPlanName)[0];
};

export const getChatSupportLink = () => {
  const bitrixAuthData = window?.BX24?.getAuth();
  if (bitrixAuthData) {
    const { domain } = bitrixAuthData;
    return `https://${domain}/online/?user_lang=en&preset=im&IM_DIALOG=networkLinesc8811abc6d27611419e1940929981445`;
  }
  return '';
};

export const getDomain = () => {
  const bitrixAuthData = window?.BX24?.getAuth();
  if (bitrixAuthData) {
    const { domain } = bitrixAuthData;
    return `https://${domain}`;
  }
  return '';
};

export const callBitrixData = async (method, data) => {
  // eslint-disable-next-line no-return-await
  return await new Promise((resolve) => {
    window.BX24.callMethod(method, data, (bitrixData) =>
      resolve(bitrixData.data())
    );
  });
};

export const times = [
  { key: '0000', value: '0000', label: '12:00 am' },
  { key: '0015', value: '0015', label: '12:15 am' },
  { key: '0030', value: '0030', label: '12:30 am' },
  { key: '0045', value: '0045', label: '12:45 am' },
  { key: '0100', value: '0100', label: '01:00 am' },
  { key: '0115', value: '0115', label: '01:15 am' },
  { key: '0130', value: '0130', label: '01:30 am' },
  { key: '0145', value: '0145', label: '01:45 am' },
  { key: '0200', value: '0200', label: '02:00 am' },
  { key: '0215', value: '0215', label: '02:15 am' },
  { key: '0230', value: '0230', label: '02:30 am' },
  { key: '0245', value: '0245', label: '02:45 am' },
  { key: '0300', value: '0300', label: '03:00 am' },
  { key: '0315', value: '0315', label: '03:15 am' },
  { key: '0330', value: '0330', label: '03:30 am' },
  { key: '0345', value: '0345', label: '03:45 am' },
  { key: '0400', value: '0400', label: '04:00 am' },
  { key: '0415', value: '0415', label: '04:15 am' },
  { key: '0430', value: '0430', label: '04:30 am' },
  { key: '0445', value: '0445', label: '04:45 am' },
  { key: '0500', value: '0500', label: '05:00 am' },
  { key: '0515', value: '0515', label: '05:15 am' },
  { key: '0530', value: '0530', label: '05:30 am' },
  { key: '0545', value: '0545', label: '05:45 am' },
  { key: '0600', value: '0600', label: '06:00 am' },
  { key: '0615', value: '0615', label: '06:15 am' },
  { key: '0630', value: '0630', label: '06:30 am' },
  { key: '0645', value: '0645', label: '06:45 am' },
  { key: '0700', value: '0700', label: '07:00 am' },
  { key: '0715', value: '0715', label: '07:15 am' },
  { key: '0730', value: '0730', label: '07:30 am' },
  { key: '0745', value: '0745', label: '07:45 am' },
  { key: '0800', value: '0800', label: '08:00 am' },
  { key: '0815', value: '0815', label: '08:15 am' },
  { key: '0830', value: '0830', label: '08:30 am' },
  { key: '0845', value: '0845', label: '08:45 am' },
  { key: '0900', value: '0900', label: '09:00 am' },
  { key: '0915', value: '0915', label: '09:15 am' },
  { key: '0930', value: '0930', label: '09:30 am' },
  { key: '0945', value: '0945', label: '09:45 am' },
  { key: '1000', value: '1000', label: '10:00 am' },
  { key: '1015', value: '1015', label: '10:15 am' },
  { key: '1030', value: '1030', label: '10:30 am' },
  { key: '1045', value: '1045', label: '10:45 am' },
  { key: '1100', value: '1100', label: '11:00 am' },
  { key: '1115', value: '1115', label: '11:15 am' },
  { key: '1130', value: '1130', label: '11:30 am' },
  { key: '1145', value: '1145', label: '11:45 am' },
  { key: '1200', value: '1200', label: '12:00 pm' },
  { key: '1215', value: '1215', label: '12:15 pm' },
  { key: '1230', value: '1230', label: '12:30 pm' },
  { key: '1245', value: '1245', label: '12:45 pm' },
  { key: '1300', value: '1300', label: '01:00 pm' },
  { key: '1315', value: '1315', label: '01:15 pm' },
  { key: '1330', value: '1330', label: '01:30 pm' },
  { key: '1345', value: '1345', label: '01:45 pm' },
  { key: '1400', value: '1400', label: '02:00 pm' },
  { key: '1415', value: '1415', label: '02:15 pm' },
  { key: '1430', value: '1430', label: '02:30 pm' },
  { key: '1445', value: '1445', label: '02:45 pm' },
  { key: '1500', value: '1500', label: '03:00 pm' },
  { key: '1515', value: '1515', label: '03:15 pm' },
  { key: '1530', value: '1530', label: '03:30 pm' },
  { key: '1545', value: '1545', label: '03:45 pm' },
  { key: '1600', value: '1600', label: '04:00 pm' },
  { key: '1615', value: '1615', label: '04:15 pm' },
  { key: '1630', value: '1630', label: '04:30 pm' },
  { key: '1645', value: '1645', label: '04:45 pm' },
  { key: '1700', value: '1700', label: '05:00 pm' },
  { key: '1715', value: '1715', label: '05:15 pm' },
  { key: '1730', value: '1730', label: '05:30 pm' },
  { key: '1745', value: '1745', label: '05:45 pm' },
  { key: '1800', value: '1800', label: '06:00 pm' },
  { key: '1815', value: '1815', label: '06:15 pm' },
  { key: '1830', value: '1830', label: '06:30 pm' },
  { key: '1845', value: '1845', label: '06:45 pm' },
  { key: '1900', value: '1900', label: '07:00 pm' },
  { key: '1915', value: '1915', label: '07:15 pm' },
  { key: '1930', value: '1930', label: '07:30 pm' },
  { key: '1945', value: '1945', label: '07:45 pm' },
  { key: '2000', value: '2000', label: '08:00 pm' },
  { key: '2015', value: '2015', label: '08:15 pm' },
  { key: '2030', value: '2030', label: '08:30 pm' },
  { key: '2045', value: '2045', label: '08:45 pm' },
  { key: '2100', value: '2100', label: '09:00 pm' },
  { key: '2115', value: '2115', label: '09:15 pm' },
  { key: '2130', value: '2130', label: '09:30 pm' },
  { key: '2145', value: '2145', label: '09:45 pm' },
  { key: '2200', value: '2200', label: '10:00 pm' },
  { key: '2215', value: '2215', label: '10:15 pm' },
  { key: '2230', value: '2230', label: '10:30 pm' },
  { key: '2245', value: '2245', label: '10:45 pm' },
  { key: '2300', value: '2300', label: '11:00 pm' },
  { key: '2315', value: '2315', label: '11:15 pm' },
  { key: '2330', value: '2330', label: '11:30 pm' },
  { key: '2345', value: '2345', label: '11:45 pm' },
];

export const timesMap = {
  '0000': '12:00 am',
  '0015': '12:15 am',
  '0030': '12:30 am',
  '0045': '12:45 am',
  '0100': '01:00 am',
  '0115': '01:15 am',
  '0130': '01:30 am',
  '0145': '01:45 am',
  '0200': '02:00 am',
  '0215': '02:15 am',
  '0230': '02:30 am',
  '0245': '02:45 am',
  '0300': '03:00 am',
  '0315': '03:15 am',
  '0330': '03:30 am',
  '0345': '03:45 am',
  '0400': '04:00 am',
  '0415': '04:15 am',
  '0430': '04:30 am',
  '0445': '04:45 am',
  '0500': '05:00 am',
  '0515': '05:15 am',
  '0530': '05:30 am',
  '0545': '05:45 am',
  '0600': '06:00 am',
  '0615': '06:15 am',
  '0630': '06:30 am',
  '0645': '06:45 am',
  '0700': '07:00 am',
  '0715': '07:15 am',
  '0730': '07:30 am',
  '0745': '07:45 am',
  '0800': '08:00 am',
  '0815': '08:15 am',
  '0830': '08:30 am',
  '0845': '08:45 am',
  '0900': '09:00 am',
  '0915': '09:15 am',
  '0930': '09:30 am',
  '0945': '09:45 am',
  1000: '10:00 am',
  1015: '10:15 am',
  1030: '10:30 am',
  1045: '10:45 am',
  1100: '11:00 am',
  1115: '11:15 am',
  1130: '11:30 am',
  1145: '11:45 am',
  1200: '12:00 pm',
  1215: '12:15 pm',
  1230: '12:30 pm',
  1245: '12:45 pm',
  1300: '01:00 pm',
  1315: '01:15 pm',
  1330: '01:30 pm',
  1345: '01:45 pm',
  1400: '02:00 pm',
  1415: '02:15 pm',
  1430: '02:30 pm',
  1445: '02:45 pm',
  1500: '03:00 pm',
  1515: '03:15 pm',
  1530: '03:30 pm',
  1545: '03:45 pm',
  1600: '04:00 pm',
  1615: '04:15 pm',
  1630: '04:30 pm',
  1645: '04:45 pm',
  1700: '05:00 pm',
  1715: '05:15 pm',
  1730: '05:30 pm',
  1745: '05:45 pm',
  1800: '06:00 pm',
  1815: '06:15 pm',
  1830: '06:30 pm',
  1845: '06:45 pm',
  1900: '07:00 pm',
  1915: '07:15 pm',
  1930: '07:30 pm',
  1945: '07:45 pm',
  2000: '08:00 pm',
  2015: '08:15 pm',
  2030: '08:30 pm',
  2045: '08:45 pm',
  2100: '09:00 pm',
  2115: '09:15 pm',
  2130: '09:30 pm',
  2145: '09:45 pm',
  2200: '10:00 pm',
  2215: '10:15 pm',
  2230: '10:30 pm',
  2245: '10:45 pm',
  2300: '11:00 pm',
  2315: '11:15 pm',
  2330: '11:30 pm',
  2345: '11:45 pm',
};

export const convertNumToTime = (num) => {
  let timeLabel = '';
  times.map((time) => {
    if (time.key === num) timeLabel = time.label;
    return null;
  });
  return timeLabel;
};

export const getDestinations = async (destinationName) => {
  const url = generateUrl(destinationName);
  let destinations = [];
  try {
    const destinationResponse = await axios.get(url);
    destinations = Object.values(destinationResponse?.data?.payload || []);
  } catch (error) {
    console.log(error);
  }
  return destinations;
};

export const ivrDigits = ['1', '2', '3', '4', '5', '6', '7', '8', '9'];

export const ivrDestinationNames = {
  employee: 'Employee',
  voicemail: 'Voicemail',
  queues: 'Queues',
  ivr: 'IVR',
  callForwarding: 'Call Forwarding',
  timeCondition: 'Business Hours',
};

export const getNewUserCost = (subscribedPlan) => {
  let newUserCost = 30;
  switch (subscribedPlan) {
    case 'start':
      newUserCost = 30;
      break;
    case 'basic':
      newUserCost = 25;
      break;
    case 'project':
      newUserCost = 23;
      break;
    case 'professional':
      newUserCost = 21;
      break;
    case 'enterprise':
      newUserCost = 19;
      break;
    default:
      return 30;
  }
  return newUserCost;
};

export const getNewDialerCost = (totalUsersWithDialer) => {
  let userPrice = 50;
  if (totalUsersWithDialer >= 2) {
    userPrice = 47;
  } else if (totalUsersWithDialer >= 5) {
    userPrice = 45;
  } else if (totalUsersWithDialer >= 25) {
    userPrice = 43;
  } else if (totalUsersWithDialer >= 50) {
    userPrice = 41;
  } else if (totalUsersWithDialer >= 100) {
    userPrice = 39;
  }
  return userPrice;
};

export const defaultCSVFields = [
  ['TITLE', 'NAME', 'LAST_NAME', 'PHONE_NUMBER', 'EMAIL'],
];

export const cardBackgrounds = [
  'rgb(31, 119, 180)',
  'rgb(44, 160, 44)',
  'rgb(255, 127, 14)',
  'rgb(148, 103, 189)',
  'rgb(214, 39, 40)',

  // '#B3C100',
  // '#E99497',
  // '#23282D',
  // '#4CB5F5',
  // '#D32D41',
  // '#1F3F49',
  // '#6AB187',
];

export const colorMap = {
  '/app/dashboard': '#FE9898',
  '/app/campaign': '#E1578A',
  '/app/reports': '#39C0F0',
  '/app/reports/call': 'rgb(253, 98, 94)',
  '/app/reports/sms': '#FFA400',
  '/app/liveCalls': '#3E3286',
  '/app/flow': '#FFC107',
  '/app/flow/voicemail': 'rgb(31, 119, 180)',
  '/app/flow/voicemailDrop': '#4BA984',
  '/app/smsusecase': '#4BA984',
  '/app/flow/queues': 'rgb(44, 160, 44)',
  '/app/flow/smsQueues': '#808E9E',
  '/app/flow/misscallBox': '#FF5151',
  '/app/flow/callbacks': '#E1578A',
  '/app/flow/callForwarding': 'rgb(255, 127, 14)',
  '/app/flow/businessHours': 'rgb(148, 103, 189)',
  '/app/flow/ivr': '#FF5151',
  '/app/dialers': '#808E9E',
  '/app/dialers/progressive': '#B67162',
  '/app/dialers/robo': '#9E9D89',
  '/app/bulkSms': '#4BA984',
  '/app/bulkSms/bulkSms': '#4BA984',
  '/app/account': '#00619B',
  '/app/account/settings': '#E1578A',
  '/app/account/subscription': 'rgb(237, 134, 7)',
  '/app/account/optoutList': '#DA0037',
  '/app/account/blockList': '#FF5151',
};

export const menuMap = {
  dashboard: '/app/dashboard',
  reports: '/app/reports',
  call: '/app/reports/call',
  sms: '/app/reports/sms',
  liveCalls: '/app/liveCalls',
  flow: '/app/flow',
  voicemail: '/app/flow/voicemail',
  voicemailDrop: '/app/flow/voicemailDrop',
  callForwarding: '/app/flow/callForwarding',
  smsQueues: '/app/flow/smsQueues',
  queues: '/app/flow/queues',
  misscallBox: '/app/flow/misscallBox',
  callbacks: '/app/flow/callbacks',
  ivr: '/app/flow/ivr',
  businessHours: '/app/flow/businessHours',
  dialers: '/app/dialers',
  progressive: '/app/dialers/progressive',
  robo: '/app/dialers/robo',
  campaign: '/app/smsusecase',
  bulkSms: '/app/bulkSms',
  account: '/app/account',
  settings: '/app/account/settings',
  subscription: '/app/account/subscription',
  optoutList: '/app/account/optoutList',
  blocktList: '/app/account/blocktList',
};

export const getIdBySipEndpoint = (sipEndpoint) => {
  return sipEndpoint.split('bitrix')[1] || '';
};

export const getScores = (vettingScore) => {
  let minuteLimit = 240;
  let dailyLimit = 2000;

  if (!vettingScore || vettingScore < 25) {
    minuteLimit = 240;
    dailyLimit = 2000;
  }
  if (vettingScore >= 25 && vettingScore < 50) {
    minuteLimit = 240;
    dailyLimit = 10000;
  }
  if (vettingScore >= 50 && vettingScore < 74) {
    minuteLimit = 2400;
    dailyLimit = 40000;
  }
  if (vettingScore >= 74) {
    minuteLimit = 4500;
    dailyLimit = 200000;
  }
  return { minuteLimit, dailyLimit };
};

export const campaignVettingFee = 15;

export const getCampaignFee = (usecase) => {
  switch (usecase) {
    case 'LOW_VOLUME':
      return { onetime: 6, monthly: 2 };
    default:
      return { onetime: 30, monthly: 10 };
  }
};

export const mapCampaignStatus = (status) => {
  switch (status) {
    case 1:
      return 'In Progress';
    case 2:
      return 'Active';
    case 3:
      return 'Failed';
    case 4:
      return 'Deletion In Progress';
    default:
      return 'Not Registered';
  }
};

export const mapNumberStatus = (status) => {
  switch (status) {
    case 0:
      return 'Not Linked';
    case 1:
      return 'In Progress';
    case 2:
      return 'Active';
    case 3:
      return 'Failed';
    case 4:
      return 'Unlink In Progress';
    default:
      return 'Not Linked';
  }
};

export const mapBrandStatus = (status) => {
  switch (status) {
    case 0:
      return 'Not Registered';
    case 1:
      return 'In Progress';
    case 2:
      return 'Active';
    case 3:
      return 'Failed';
    default:
      return 'Not Registered';
  }
};
