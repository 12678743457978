import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import axios from 'axios';

import {
  ROBO_GET_LIST,
  ROBO_ADD_ITEM,
  ROBO_GET_EMPLOYEE_LIST,
  ROBO_GET_NUMBERS_LIST,
} from '../actions';

import {
  getRoboListSuccess,
  getRoboListError,
  addRoboItemSuccess,
  addRoboItemError,
  getRoboEmployeeListsSuccess,
  getRoboEmployeeListsError,
  getRoboNumbersListsSuccess,
  getRoboNumbersListsError,
} from './actions';

const getRoboEmployeeListRequest = async () => {
  // eslint-disable-next-line no-return-await
  return await new Promise((success) => {
    axios
      .get('/user/getDialerEmployees/robo')
      .then((response) => success(response?.data?.payload))
      .catch(() => success([]));
  })
    .then((response) => response)
    .catch((error) => error);
};

function* getRoboEmployeesItems() {
  try {
    const response = yield call(getRoboEmployeeListRequest);
    yield put(getRoboEmployeeListsSuccess(response));
  } catch (error) {
    yield put(getRoboEmployeeListsError(error));
  }
}

const getRoboNumbersListRequest = async () => {
  // eslint-disable-next-line no-return-await
  return await new Promise((success) => {
    axios
      .get('/user/getNumbers')
      .then((response) => success(response?.data?.payload))
      .catch(() => success([]));
  })
    .then((response) => response)
    .catch((error) => error);
};

function* getRoboNumberssItems() {
  try {
    const response = yield call(getRoboNumbersListRequest);
    yield put(getRoboNumbersListsSuccess(response));
  } catch (error) {
    yield put(getRoboNumbersListsError(error));
  }
}

const getRoboListRequest = async () => {
  // eslint-disable-next-line no-return-await
  return await new Promise((success) => {
    axios
      .get('/dialers/robo/robos/')
      .then(
        (response) => response && success(Object.values(response?.data.payload))
      )
      .catch(() => success([]));
  })
    .then((response) => response)
    .catch((error) => error);
};

function* getRoboListItems() {
  try {
    const response = yield call(getRoboListRequest);
    yield put(getRoboListSuccess(response));
  } catch (error) {
    yield put(getRoboListError(error));
  }
}

const addRoboItemRequest = async (item) => {
  // eslint-disable-next-line no-return-await
  return await new Promise((success) => {
    axios
      .post('/dialers/robo', { ...item })
      .then((response) => success(response?.data?.payload))
      .catch(() => success(null));
  })
    .then((response) => response)
    .catch((error) => error);
};

function* addRoboItem({ payload }) {
  try {
    const response = yield call(addRoboItemRequest, payload);
    yield put(addRoboItemSuccess(response));
  } catch (error) {
    yield put(addRoboItemError(error));
  }
}

export function* watchGetList() {
  yield takeEvery(ROBO_GET_LIST, getRoboListItems);
}

export function* watchGetRoboEmployeesList() {
  yield takeEvery(ROBO_GET_EMPLOYEE_LIST, getRoboEmployeesItems);
}

export function* watchGetRoboNumberssList() {
  yield takeEvery(ROBO_GET_NUMBERS_LIST, getRoboNumberssItems);
}

export function* wathcAddItem() {
  yield takeEvery(ROBO_ADD_ITEM, addRoboItem);
}

export default function* rootSaga() {
  yield all([
    fork(watchGetList),
    fork(wathcAddItem),
    fork(watchGetRoboEmployeesList),
    fork(watchGetRoboNumberssList),
  ]);
}
