import { defaultDestinationNames } from '../../helpers/Utils';
import {
  IVR_GET_LIST,
  IVR_GET_LIST_SUCCESS,
  IVR_GET_LIST_ERROR,
  IVR_ADD_ITEM,
  IVR_ADD_ITEM_SUCCESS,
  IVR_ADD_ITEM_ERROR,
  IVR_EDIT_ITEM,
  IVR_EDIT_ITEM_SUCCESS,
  IVR_EDIT_ITEM_ERROR,
  IVR_FAIL_OVER_DESTINATIONS_LIST,
  IVR_FAIL_OVER_DESTINATIONS_LIST_SUCCESS,
  IVR_FAIL_OVER_DESTINATIONS_LIST_ERROR,
} from '../actions';

const INIT_STATE = {
  ivrs: [],
  ivrFailOverDestinationNames: { ...defaultDestinationNames, hangup: 'Hangup' },
  ivrFailOverDestinations: [],
  title: '',
  error: '',
  loading: false,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case IVR_GET_LIST:
      return { ...state, loading: false };

    case IVR_GET_LIST_SUCCESS:
      return {
        ...state,
        loading: true,
        ivrs: action.payload,
      };

    case IVR_GET_LIST_ERROR:
      return { ...state, loading: true, error: action.payload };

    case IVR_FAIL_OVER_DESTINATIONS_LIST:
      return { ...state, loading: false };

    case IVR_FAIL_OVER_DESTINATIONS_LIST_SUCCESS:
      return {
        ...state,
        loading: true,
        ivrFailOverDestinations: action.payload,
      };

    case IVR_FAIL_OVER_DESTINATIONS_LIST_ERROR:
      return { ...state, loading: true, error: action.payload };

    case IVR_ADD_ITEM:
      return { ...state, loading: false };

    case IVR_ADD_ITEM_SUCCESS:
      if (action.payload === null) {
        return {
          ...state,
          loading: true,
        };
      }
      window.location.reload();
      return {
        ...state,
        loading: true,
        ivrs: action.payload,
      };

    case IVR_ADD_ITEM_ERROR:
      return { ...state, loading: true, error: action.payload };

    case IVR_EDIT_ITEM:
      return { ...state, loading: false };

    case IVR_EDIT_ITEM_SUCCESS:
      if (action.payload === null) {
        return {
          ...state,
          loading: true,
        };
      }
      window.location.reload();
      return {
        ...state,
        loading: true,
        ivrs: action.payload,
      };

    case IVR_EDIT_ITEM_ERROR:
      return { ...state, loading: true, error: action.payload };

    default:
      return { ...state };
  }
};
