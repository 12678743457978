import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import axios from 'axios';

import {
  PROGRESSIVE_GET_LIST,
  PROGRESSIVE_ADD_ITEM,
  PROGRESSIVE_GET_EMPLOYEE_LIST,
  PROGRESSIVE_GET_NUMBERS_LIST,
} from '../actions';

import {
  getProgressiveListSuccess,
  getProgressiveListError,
  addProgressiveItemSuccess,
  addProgressiveItemError,
  getProgressiveEmployeeListsSuccess,
  getProgressiveEmployeeListsError,
  getProgressiveNumbersListsSuccess,
  getProgressiveNumbersListsError,
} from './actions';

const getProgressiveEmployeeListRequest = async () => {
  // eslint-disable-next-line no-return-await
  return await new Promise((success) => {
    axios
      .get('/user/getDialerEmployees/progressive')
      .then((response) => success(response?.data?.payload))
      .catch((error) => error);
  })
    .then((response) => response)
    .catch((error) => error);
};

function* getProgressiveEmployeesItems() {
  try {
    const response = yield call(getProgressiveEmployeeListRequest);
    yield put(getProgressiveEmployeeListsSuccess(response));
  } catch (error) {
    yield put(getProgressiveEmployeeListsError(error));
  }
}

const getProgressiveNumbersListRequest = async () => {
  // eslint-disable-next-line no-return-await
  return await new Promise((success) => {
    axios
      .get('/user/getNumbers')
      .then((response) => success(response?.data?.payload))
      .catch(() => success([]));
  })
    .then((response) => response)
    .catch((error) => error);
};

function* getProgressiveNumberssItems() {
  try {
    const response = yield call(getProgressiveNumbersListRequest);
    yield put(getProgressiveNumbersListsSuccess(response));
  } catch (error) {
    yield put(getProgressiveNumbersListsError(error));
  }
}

const getProgressiveListRequest = async () => {
  // eslint-disable-next-line no-return-await
  return await new Promise((success) => {
    axios
      .get('/dialers/progressive/progressives/')
      .then(
        (response) => response && success(Object.values(response?.data.payload))
      )
      .catch(() => success([]));
  })
    .then((response) => response)
    .catch((error) => error);
};

function* getProgressiveListItems() {
  try {
    const response = yield call(getProgressiveListRequest);
    yield put(getProgressiveListSuccess(response));
  } catch (error) {
    yield put(getProgressiveListError(error));
  }
}

const addProgressiveItemRequest = async (item) => {
  // eslint-disable-next-line no-return-await
  return await new Promise((success) => {
    axios
      .post('/dialers/progressive', { ...item })
      .then((response) => success(response?.data?.payload))
      .catch(() => success(null));
  })
    .then((response) => response)
    .catch((error) => error);
};

function* addProgressiveItem({ payload }) {
  try {
    const response = yield call(addProgressiveItemRequest, payload);
    yield put(addProgressiveItemSuccess(response));
  } catch (error) {
    yield put(addProgressiveItemError(error));
  }
}

export function* watchGetList() {
  yield takeEvery(PROGRESSIVE_GET_LIST, getProgressiveListItems);
}

export function* watchGetProgressiveEmployeesList() {
  yield takeEvery(PROGRESSIVE_GET_EMPLOYEE_LIST, getProgressiveEmployeesItems);
}

export function* watchGetProgressiveNumberssList() {
  yield takeEvery(PROGRESSIVE_GET_NUMBERS_LIST, getProgressiveNumberssItems);
}

export function* wathcAddItem() {
  yield takeEvery(PROGRESSIVE_ADD_ITEM, addProgressiveItem);
}

export default function* rootSaga() {
  yield all([
    fork(watchGetList),
    fork(wathcAddItem),
    fork(watchGetProgressiveEmployeesList),
    fork(watchGetProgressiveNumberssList),
  ]);
}
