import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import axios from 'axios';

import {
  IVR_GET_LIST,
  IVR_ADD_ITEM,
  IVR_EDIT_ITEM,
  IVR_FAIL_OVER_DESTINATIONS_LIST,
} from '../actions';

import {
  getIVRListSuccess,
  getIVRListError,
  addIVRItemSuccess,
  addIVRItemError,
  editIVRItemSuccess,
  editIVRItemError,
  getIVRFailOverDestinationsSuccess,
  getIVRFailOverDestinationsError,
} from './actions';

import { generateUrl } from '../../helpers/Utils';

const getIVRFailOverDestinationsRequest = async (destinationName) => {
  const url = generateUrl(destinationName);

  // eslint-disable-next-line no-return-await
  return await new Promise((success) => {
    axios
      .get(url)
      .then((response) => success(Object.values(response?.data?.payload)))
      .catch(() => success([]));
  })
    .then((response) => response)
    .catch((error) => error);
};

function* getIVRFailOverDestinationsItems({ payload }) {
  try {
    const response = yield call(getIVRFailOverDestinationsRequest, payload);
    yield put(getIVRFailOverDestinationsSuccess(response));
  } catch (error) {
    yield put(getIVRFailOverDestinationsError(error));
  }
}

const getIVRListRequest = async () => {
  // eslint-disable-next-line no-return-await
  return await new Promise((success) => {
    axios
      .get('/flow/ivrs/')
      .then((response) => success(Object.values(response?.data?.payload)))
      .catch(() => success([]));
  })
    .then((response) => response)
    .catch((error) => error);
};

function* getIVRListItems() {
  try {
    const response = yield call(getIVRListRequest);
    yield put(getIVRListSuccess(response));
  } catch (error) {
    yield put(getIVRListError(error));
  }
}

const addIVRItemRequest = async (item) => {
  // eslint-disable-next-line no-return-await
  return await new Promise((success) => {
    axios
      .post('/flow/ivr', { ...item })
      .then((response) => success(response.data.ivrs))
      .catch(() => success(null));
  })
    .then((response) => response)
    .catch((error) => error);
};

function* addIVRItem({ payload }) {
  try {
    const response = yield call(addIVRItemRequest, payload);
    yield put(addIVRItemSuccess(response));
  } catch (error) {
    yield put(addIVRItemError(error));
  }
}

const editIVRItemRequest = async (item) => {
  // eslint-disable-next-line no-return-await
  return await new Promise((success) => {
    axios
      .put(`/flow/ivr/${item.ivrId}`, { ...item })
      .then((response) => success(response.data.ivrs))
      .catch(() => success(null));
  })
    .then((response) => response)
    .catch((error) => error);
};

function* editIVRItem({ payload }) {
  try {
    const response = yield call(editIVRItemRequest, payload);
    yield put(editIVRItemSuccess(response));
  } catch (error) {
    yield put(editIVRItemError(error));
  }
}

export function* watchGetList() {
  yield takeEvery(IVR_GET_LIST, getIVRListItems);
}

export function* watchGetIVRFailOverDestinationsList() {
  yield takeEvery(
    IVR_FAIL_OVER_DESTINATIONS_LIST,
    getIVRFailOverDestinationsItems
  );
}

export function* wathcAddItem() {
  yield takeEvery(IVR_ADD_ITEM, addIVRItem);
}

export function* wathcEditItem() {
  yield takeEvery(IVR_EDIT_ITEM, editIVRItem);
}

export default function* rootSaga() {
  yield all([
    fork(watchGetList),
    fork(wathcAddItem),
    fork(wathcEditItem),
    fork(watchGetIVRFailOverDestinationsList),
  ]);
}
