import {
  CAMPAIGN_GET_LIST,
  CAMPAIGN_GET_LIST_SUCCESS,
  CAMPAIGN_GET_LIST_ERROR,
  CAMPAIGN_ADD_ITEM,
  CAMPAIGN_ADD_ITEM_SUCCESS,
  CAMPAIGN_ADD_ITEM_ERROR,
} from '../actions';

const INIT_STATE = {
  campaigns: [],
  verticals: [
    'PROFESSIONAL',
    'REAL_ESTATE',
    'HEALTHCARE',
    'HUMAN_RESOURCES',
    'ENERGY',
    'ENTERTAINMENT',
    'RETAIL',
    'TRANSPORTATION',
    'AGRICULTURE',
    'INSURANCE',
    'POSTAL',
    'EDUCATION',
    'HOSPITALITY',
    'FINANCIAL',
    'POLITICAL',
    'GAMBLING',
    'LEGAL',
    'CONSTRUCTION',
    'NGO',
    'MANUFACTURING',
    'GOVERNMENT',
    'TECHNOLOGY',
    'COMMUNICATION',
  ],
  usecases: [
    '2FA',
    'ACCOUNT_NOTIFICATION',
    'CUSTOMER_CARE',
    'DELIVERY_NOTIFICATION',
    'FRAUD_ALERT',
    'HIGHER_EDUCATION',
    'LOW_VOLUME',
    'MARKETING',
    'MIXED',
    'POLLING_VOTING',
    'PUBLIC_SERVICE_ANNOUNCEMENT',
    'SECURITY_ALERT',
  ],
  subusecases: [
    'ACCOUNT_NOTIFICATION',
    'CUSTOMER_CARE',
    'DELIVERY_NOTIFICATION',
    'FRAUD_ALERT',
    'HIGHER_EDUCATION',
    'MARKETING',
    'POLLING_VOTING',
    'PUBLIC_SERVICE_ANNOUNCEMENT',
    'SECURITY_ALERT',
  ],
  error: '',
  loading: false,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case CAMPAIGN_GET_LIST:
      return { ...state, loading: false };

    case CAMPAIGN_GET_LIST_SUCCESS:
      return {
        ...state,
        loading: true,
        campaigns: action.payload,
      };

    case CAMPAIGN_GET_LIST_ERROR:
      return { ...state, campaigns: [], loading: true, error: action.payload };

    case CAMPAIGN_ADD_ITEM:
      return { ...state, loading: false };

    case CAMPAIGN_ADD_ITEM_SUCCESS:
      if (action.payload === null) {
        return {
          ...state,
          loading: true,
        };
      }
      window.location.reload();
      return {
        ...state,
        loading: true,
        campaigns: action.payload,
      };

    case CAMPAIGN_ADD_ITEM_ERROR:
      console.log('error runned');
      return { ...state, campaigns: [], loading: true, error: action.payload };

    default:
      return { ...state };
  }
};
