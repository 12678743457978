import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import axios from 'axios';

import {
  TIME_CONDITIONS_GET_LIST,
  TIME_CONDITIONS_ADD_ITEM,
  TIME_CONDITIONS_EDIT_ITEM,
  TIMECONDITION_DESTINATIONS_LIST,
  TIMECONDITION_NON_DESTINATIONS_LIST,
} from '../actions';

import {
  getTimeConditionsListSuccess,
  getTimeConditionsListError,
  addTimeConditionsItemSuccess,
  addTimeConditionsItemError,
  editTimeConditionsItemSuccess,
  editTimeConditionsItemError,
  getTimeConditionDestinationsSuccess,
  getTimeConditionDestinationsError,
  getTimeConditionNonDestinationsSuccess,
  getTimeConditionNonDestinationsError,
} from './actions';

import { generateUrl } from '../../helpers/Utils';

const getTimeConditionDestinationsRequest = async (destinationName) => {
  const url = generateUrl(destinationName);

  // eslint-disable-next-line no-return-await
  return await new Promise((success) => {
    axios
      .get(url)
      .then((response) => success(Object.values(response?.data?.payload)))
      .catch(() => success([]));
  })
    .then((response) => response)
    .catch((error) => error);
};

function* getTimeConditionDestinationsItems({ payload }) {
  try {
    const response = yield call(getTimeConditionDestinationsRequest, payload);
    yield put(getTimeConditionDestinationsSuccess(response));
  } catch (error) {
    yield put(getTimeConditionDestinationsError(error));
  }
}

const getTimeConditionNonDestinationsRequest = async (nonDestinationName) => {
  const url = generateUrl(nonDestinationName);

  // eslint-disable-next-line no-return-await
  return await new Promise((success) => {
    axios
      .get(url)
      .then((response) => success(Object.values(response?.data?.payload)))
      .catch(() => success([]));
  })
    .then((response) => response)
    .catch((error) => error);
};

function* getTimeConditionNonDestinationsItems({ payload }) {
  try {
    const response = yield call(
      getTimeConditionNonDestinationsRequest,
      payload
    );
    yield put(getTimeConditionNonDestinationsSuccess(response));
  } catch (error) {
    yield put(getTimeConditionNonDestinationsError(error));
  }
}

const getTimeConditionsListRequest = async () => {
  // eslint-disable-next-line no-return-await
  return await new Promise((success) => {
    axios
      .get('/flow/timeConditions/')
      .then((response) => success(Object.values(response?.data?.payload)))
      .catch(() => success([]));
  })
    .then((response) => response)
    .catch((error) => error);
};

function* getTimeConditionsListItems() {
  try {
    const response = yield call(getTimeConditionsListRequest);
    yield put(getTimeConditionsListSuccess(response));
  } catch (error) {
    yield put(getTimeConditionsListError(error));
  }
}

const addTimeConditionsItemRequest = async (item) => {
  // eslint-disable-next-line no-return-await
  return await new Promise((success) => {
    axios
      .post('/flow/timeCondition', { ...item })
      .then((response) => success(response.data.timeConditions))
      .catch(() => success(null));
  })
    .then((response) => response)
    .catch((error) => error);
};

function* addTimeConditionsItem({ payload }) {
  try {
    const response = yield call(addTimeConditionsItemRequest, payload);
    yield put(addTimeConditionsItemSuccess(response));
  } catch (error) {
    yield put(addTimeConditionsItemError(error));
  }
}

const editTimeConditionsItemRequest = async (item) => {
  // eslint-disable-next-line no-return-await
  return await new Promise((success) => {
    axios
      .put(`/flow/timeCondition/${item.timeConditionId}`, { ...item })
      .then((response) => success(response.data.timeConditions))
      .catch(() => success(null));
  })
    .then((response) => response)
    .catch((error) => error);
};

function* editTimeConditionsItem({ payload }) {
  try {
    const response = yield call(editTimeConditionsItemRequest, payload);
    yield put(editTimeConditionsItemSuccess(response));
  } catch (error) {
    yield put(editTimeConditionsItemError(error));
  }
}

export function* watchGetList() {
  yield takeEvery(TIME_CONDITIONS_GET_LIST, getTimeConditionsListItems);
}

export function* watchGetTimeConditionDestinationsList() {
  yield takeEvery(
    TIMECONDITION_DESTINATIONS_LIST,
    getTimeConditionDestinationsItems
  );
}

export function* watchGetTimeConditionNonDestinationsList() {
  yield takeEvery(
    TIMECONDITION_NON_DESTINATIONS_LIST,
    getTimeConditionNonDestinationsItems
  );
}

export function* wathcAddItem() {
  yield takeEvery(TIME_CONDITIONS_ADD_ITEM, addTimeConditionsItem);
}

export function* wathcEditItem() {
  yield takeEvery(TIME_CONDITIONS_EDIT_ITEM, editTimeConditionsItem);
}

export default function* rootSaga() {
  yield all([
    fork(watchGetList),
    fork(wathcAddItem),
    fork(wathcEditItem),
    fork(watchGetTimeConditionDestinationsList),
    fork(watchGetTimeConditionNonDestinationsList),
  ]);
}
