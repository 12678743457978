// eslint-disable-next-line import/no-cycle
import {
  VOICEMAIL_DROP_GET_LIST,
  VOICEMAIL_DROP_GET_LIST_SUCCESS,
  VOICEMAIL_DROP_GET_LIST_ERROR,
  VOICEMAIL_DROP_ADD_ITEM,
  VOICEMAIL_DROP_ADD_ITEM_SUCCESS,
  VOICEMAIL_DROP_ADD_ITEM_ERROR,
  VOICEMAIL_DROP_EDIT_ITEM,
  VOICEMAIL_DROP_EDIT_ITEM_SUCCESS,
  VOICEMAIL_DROP_EDIT_ITEM_ERROR,
} from '../actions';

export const getVoicemailDropList = () => ({
  type: VOICEMAIL_DROP_GET_LIST,
});

export const getVoicemailDropListSuccess = (items) => ({
  type: VOICEMAIL_DROP_GET_LIST_SUCCESS,
  payload: items,
});

export const getVoicemailDropListError = (error) => ({
  type: VOICEMAIL_DROP_GET_LIST_ERROR,
  payload: error,
});

export const addVoicemailDropItem = (item) => ({
  type: VOICEMAIL_DROP_ADD_ITEM,
  payload: item,
});

export const addVoicemailDropItemSuccess = (items) => ({
  type: VOICEMAIL_DROP_ADD_ITEM_SUCCESS,
  payload: items,
});

export const addVoicemailDropItemError = (error) => ({
  type: VOICEMAIL_DROP_ADD_ITEM_ERROR,
  payload: error,
});

export const editVoicemailDropItem = (item) => ({
  type: VOICEMAIL_DROP_EDIT_ITEM,
  payload: item,
});

export const editVoicemailDropItemSuccess = (items) => ({
  type: VOICEMAIL_DROP_EDIT_ITEM_SUCCESS,
  payload: items,
});

export const editVoicemailDropItemError = (error) => ({
  type: VOICEMAIL_DROP_EDIT_ITEM_ERROR,
  payload: error,
});
