import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import axios from 'axios';

import {
  QUEUES_GET_LIST,
  QUEUES_ADD_ITEM,
  QUEUES_EDIT_ITEM,
  QUEUES_GET_EMPLOYEE_LIST,
} from '../actions';

import {
  getQueuesListSuccess,
  getQueuesListError,
  addQueueItemSuccess,
  addQueueItemError,
  editQueueItemSuccess,
  editQueueItemError,
  getQueuesEmployeeListsSuccess,
  getQueuesEmployeeListsError,
} from './actions';

const getQueuesEmployeeListRequest = async () => {
  // eslint-disable-next-line no-return-await
  return await new Promise((success) => {
    axios
      .get('/user/getSipUsers/')
      .then((response) => success(response?.data?.payload))
      .catch(() => success([]));
  })
    .then((response) => response)
    .catch((error) => error);
};

function* getQueuesEmployeeListItems() {
  try {
    const response = yield call(getQueuesEmployeeListRequest);
    yield put(getQueuesEmployeeListsSuccess(response));
  } catch (error) {
    yield put(getQueuesEmployeeListsError(error));
  }
}

const getQueuesListRequest = async () => {
  // eslint-disable-next-line no-return-await
  return await new Promise((success) => {
    axios
      .get('/flow/queues/')
      .then((response) => success(Object.values(response?.data?.payload)))
      .catch(() => success([]));
  })
    .then((response) => response)
    .catch((error) => error);
};

function* getQueuesListItems() {
  try {
    const response = yield call(getQueuesListRequest);
    yield put(getQueuesListSuccess(response));
  } catch (error) {
    yield put(getQueuesListError(error));
  }
}

const addQueueItemRequest = async (item) => {
  // eslint-disable-next-line no-return-await
  return await new Promise((success) => {
    axios
      .post('/flow/queue', { ...item })
      .then((response) => success(response.data.queues))
      .catch(() => success(null));
  })
    .then((response) => response)
    .catch((error) => error);
};

function* addQueueItem({ payload }) {
  try {
    const response = yield call(addQueueItemRequest, payload);
    yield put(addQueueItemSuccess(response));
  } catch (error) {
    yield put(addQueueItemError(error));
  }
}

const editQueueItemRequest = async (item) => {
  // eslint-disable-next-line no-return-await
  return await new Promise((success) => {
    axios
      .put(`/flow/queue/${item.queueId}`, { ...item })
      .then((response) => success(response.data.queues))
      .catch(() => success(null));
  })
    .then((response) => response)
    .catch((error) => error);
};

function* editQueueItem({ payload }) {
  try {
    const response = yield call(editQueueItemRequest, payload);
    yield put(editQueueItemSuccess(response));
  } catch (error) {
    yield put(editQueueItemError(error));
  }
}

export function* watchGetList() {
  yield takeEvery(QUEUES_GET_LIST, getQueuesListItems);
}

export function* watchGetQueuesEmployeeList() {
  yield takeEvery(QUEUES_GET_EMPLOYEE_LIST, getQueuesEmployeeListItems);
}

export function* wathcAddItem() {
  yield takeEvery(QUEUES_ADD_ITEM, addQueueItem);
}

export function* wathcEditItem() {
  yield takeEvery(QUEUES_EDIT_ITEM, editQueueItem);
}

export default function* rootSaga() {
  yield all([
    fork(watchGetList),
    fork(wathcAddItem),
    fork(wathcEditItem),
    fork(watchGetQueuesEmployeeList),
  ]);
}
