import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import axios from 'axios';

import {
  CALLBACK_GET_LIST,
  CALLBACK_ADD_ITEM,
  CALLBACK_EDIT_ITEM,
  CALLBACK_GET_EMPLOYEE_LIST,
  CALLBACK_GET_NUMBER_LIST,
} from '../actions';

import {
  getCallbacksListSuccess,
  getCallbacksListError,
  addCallbackItemSuccess,
  addCallbackItemError,
  editCallbackItemSuccess,
  editCallbackItemError,
  getCallbacksEmployeeListsSuccess,
  getCallbacksEmployeeListsError,
  getCallbacksNumberListsSuccess,
  getCallbacksNumberListsError,
} from './actions';

const getCallbacksEmployeeListRequest = async () => {
  // eslint-disable-next-line no-return-await
  return await new Promise((success) => {
    axios
      .get('/user/getSipUsers/')
      .then((response) => success(response?.data?.payload))
      .catch(() => success([]));
  })
    .then((response) => response)
    .catch((error) => error);
};

function* getCallbacksEmployeeListItems() {
  try {
    const response = yield call(getCallbacksEmployeeListRequest);
    yield put(getCallbacksEmployeeListsSuccess(response));
  } catch (error) {
    yield put(getCallbacksEmployeeListsError(error));
  }
}

const getCallbacksNumberListRequest = async () => {
  // eslint-disable-next-line no-return-await
  return await new Promise((success) => {
    axios
      .get('/user/getCallbackNumbers/')
      .then((response) => success(response?.data?.payload))
      .catch(() => success([]));
  })
    .then((response) => response)
    .catch((error) => error);
};

function* getCallbacksNumberListItems() {
  try {
    const response = yield call(getCallbacksNumberListRequest);
    yield put(getCallbacksNumberListsSuccess(response));
  } catch (error) {
    yield put(getCallbacksNumberListsError(error));
  }
}

const getCallbacksListRequest = async () => {
  // eslint-disable-next-line no-return-await
  return await new Promise((success) => {
    axios
      .get('/flow/callbacks/')
      .then((response) => success(Object.values(response?.data?.payload)))
      .catch(() => success([]));
  })
    .then((response) => response)
    .catch((error) => error);
};

function* getCallbacksListItems() {
  try {
    const response = yield call(getCallbacksListRequest);
    yield put(getCallbacksListSuccess(response));
  } catch (error) {
    yield put(getCallbacksListError(error));
  }
}

const addCallbackItemRequest = async (item) => {
  // eslint-disable-next-line no-return-await
  return await new Promise((success) => {
    axios
      .post('/flow/callback', { ...item })
      .then((response) => success(response.data.callbacks))
      .catch(() => success(null));
  })
    .then((response) => response)
    .catch((error) => error);
};

function* addCallbackItem({ payload }) {
  try {
    const response = yield call(addCallbackItemRequest, payload);
    yield put(addCallbackItemSuccess(response));
  } catch (error) {
    yield put(addCallbackItemError(error));
  }
}

const editCallbackItemRequest = async (item) => {
  // eslint-disable-next-line no-return-await
  return await new Promise((success) => {
    axios
      .put(`/flow/callback/${item.callbackId}`, { ...item })
      .then((response) => success(response.data.callbacks))
      .catch(() => success(null));
  })
    .then((response) => response)
    .catch((error) => error);
};

function* editCallbackItem({ payload }) {
  try {
    const response = yield call(editCallbackItemRequest, payload);
    yield put(editCallbackItemSuccess(response));
  } catch (error) {
    yield put(editCallbackItemError(error));
  }
}

export function* watchGetList() {
  yield takeEvery(CALLBACK_GET_LIST, getCallbacksListItems);
}

export function* watchGetCallbacksEmployeeList() {
  yield takeEvery(CALLBACK_GET_EMPLOYEE_LIST, getCallbacksEmployeeListItems);
}

export function* watchGetCallbacksNumberList() {
  yield takeEvery(CALLBACK_GET_NUMBER_LIST, getCallbacksNumberListItems);
}

export function* wathcAddItem() {
  yield takeEvery(CALLBACK_ADD_ITEM, addCallbackItem);
}

export function* wathcEditItem() {
  yield takeEvery(CALLBACK_EDIT_ITEM, editCallbackItem);
}

export default function* rootSaga() {
  yield all([
    fork(watchGetList),
    fork(wathcAddItem),
    fork(wathcEditItem),
    fork(watchGetCallbacksEmployeeList),
    fork(watchGetCallbacksNumberList),
  ]);
}
