import { combineReducers } from 'redux';
import settings from './settings/reducer';
import menu from './menu/reducer';
import authUser from './auth/reducer';
import todoApp from './todo/reducer';
import queuesApp from './queues/reducer';
import smsQueuesApp from './smsQueues/reducer';
import misscallBoxApp from './misscallBox/reducer';
import campaignApp from './campaigns/reducer';
import callbacksApp from './callbacks/reducer';
import timeConditionsApp from './timeConditions/reducer';
import ivrApp from './ivr/reducer';
import voicemailApp from './voicemail/reducer';
import voicemailDropApp from './voicemailDrop/reducer';
import callForwardingApp from './callForwarding/reducer';
import roboDialerApp from './roboDialer/reducer';
import progressiveDialerApp from './progressiveDialer/reducer';
import bulkSmsApp from './bulkSms/reducer';
import chatApp from './chat/reducer';
import surveyListApp from './surveyList/reducer';
import surveyDetailApp from './surveyDetail/reducer';

const reducers = combineReducers({
  menu,
  settings,
  authUser,
  todoApp,
  queuesApp,
  smsQueuesApp,
  misscallBoxApp,
  callbacksApp,
  timeConditionsApp,
  campaignApp,
  ivrApp,
  voicemailApp,
  voicemailDropApp,
  callForwardingApp,
  chatApp,
  surveyListApp,
  surveyDetailApp,
  roboDialerApp,
  progressiveDialerApp,
  bulkSmsApp,
});

export default reducers;
