import {
  BULK_SMS_GET_LIST,
  BULK_SMS_GET_LIST_SUCCESS,
  BULK_SMS_GET_LIST_ERROR,
  BULK_SMS_ADD_ITEM,
  BULK_SMS_ADD_ITEM_SUCCESS,
  BULK_SMS_ADD_ITEM_ERROR,
  BULK_SMS_GET_NUMBERS_LIST,
  BULK_SMS_GET_NUMBERS_LIST_SUCCESS,
  BULK_SMS_GET_NUMBERS_LIST_ERROR,
} from '../actions';

const INIT_STATE = {
  bulkSms: [],
  numbers: [],
  title: '',
  error: '',
  loading: false,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case BULK_SMS_GET_LIST:
      return { ...state, loading: false };

    case BULK_SMS_GET_LIST_SUCCESS:
      return {
        ...state,
        loading: true,
        bulkSms: action.payload,
      };

    case BULK_SMS_GET_LIST_ERROR:
      return { ...state, loading: true, error: action.payload };

    case BULK_SMS_GET_NUMBERS_LIST:
      return { ...state, loading: false };

    case BULK_SMS_GET_NUMBERS_LIST_SUCCESS:
      return {
        ...state,
        loading: true,
        numbers: action.payload,
      };

    case BULK_SMS_GET_NUMBERS_LIST_ERROR:
      return { ...state, loading: true, error: action.payload };

    case BULK_SMS_ADD_ITEM:
      return { ...state, loading: false };

    case BULK_SMS_ADD_ITEM_SUCCESS:
      if (action.payload === null) {
        return {
          ...state,
          loading: true,
        };
      }
      window.location.reload();
      return {
        ...state,
        loading: true,
        bulkSms: action.payload,
      };

    case BULK_SMS_ADD_ITEM_ERROR:
      return { ...state, loading: true, error: action.payload };

    default:
      return { ...state };
  }
};
